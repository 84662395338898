import axios from "axios";
import { BASE_URL } from "../Constants";
const API_URL = BASE_URL;
class SignUpService
{
    async signUp(body)
    {
        return axios.post(API_URL + "Auth/SignUp", body);
    }

    async confirmSignUp(body)
    {
        return axios.post(API_URL + "Auth/ConfirmSignUp", body);
    }

    async resendOtp(email)
    {
        return axios.post(API_URL + "Auth/ResendSignUpOTP?email=" + email );
    }

    



}
export default new SignUpService