import { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSchedules,
  resetAddSchedules,
} from "../../Actions/Practitioner/addScheduleAction";
import { setSelection } from "../../Actions/Practitioner/addServiceAction";
import { toast } from "react-toastify";
import Loader from "../SubComponent/Loader";
import shadows from "@mui/material/styles/shadows";

const generateTimeOptions = () => {
  const times = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  for (let i = 0; i <= 47; i++) {
    const time = new Date(start);
    time.setMinutes(start.getMinutes() + i * 30);
    times.push(time);
  }

  return times;
};

const timeOptions = generateTimeOptions();

const AddSchedule = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [toggleWeek, setToggleWeek] = useState("week1");
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scheduleSlots, setScheduleSlots] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const id = UserData.user.id;
  const addSchedule = useSelector((state) => state.addSchedule.addSchedule);

  console.log(selectedDate);
  
  const formatTime12h = (date) => format(date, "hh:mm a");
  const handleDateChange = (date) => {
    setErrorMessage("");
    setSelectedDate(date);
    setEndDate(addDays(date, 13));
  };

  const handleToggle = (event, newToggle) => {
    setToggleWeek(newToggle);
  };

  const getWeekDates = (startDate, weekNumber) => {
    const start = addDays(startDate, weekNumber === "week2" ? 7 : 0);
    return Array.from({ length: 7 }, (_, i) => addDays(start, i));
  };

  const handleAddSlot = (day) => {
    const newSlot = { start: null, end: null };
    setScheduleSlots((prev) => ({
      ...prev,
      [day]: [...(prev[day] || []), newSlot],
    }));
  };

  const handleRemoveSlot = (day, index) => {
    setScheduleSlots((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
  };

  const handleSlotChange = (day, index, key, value) => {
    setScheduleSlots((prev) => {
      const updatedSlots = prev[day].map((slot, i) => {
        if (i === index) {
          return { ...slot, [key]: value };
        }
        return slot;
      });

      const startTime = updatedSlots[index].start;
      const endTime = updatedSlots[index].end;

      if (startTime && endTime && endTime <= startTime) {
        setErrorMessage("End time must be after the start time.");
        return prev;
      }

      const overlapping = updatedSlots.some((slot, i) => {
        if (i !== index && slot.start && slot.end) {
          return (
            (startTime >= slot.start && startTime < slot.end) ||
            (endTime > slot.start && endTime <= slot.end)
          );
        }
        return false;
      });

      if (overlapping) {
        setErrorMessage("There is already a slot for this time");
        return prev;
      }

      setErrorMessage("");
      return { ...prev, [day]: updatedSlots };
    });
  };

  const handleDaySelect = (date) => {
    setSelectedDay(date);
  };

  const weekDates = selectedDate ? getWeekDates(selectedDate, toggleWeek) : [];
  const selectedDayFormatted = selectedDay
    ? format(selectedDay, "yyyy-MM-dd")
    : "";

  const handleSave = () => {
    const startYear = selectedDate.getFullYear();
    const timezoneOffsetMinutes = selectedDate.getTimezoneOffset();
    const timezoneOffsetHours = (timezoneOffsetMinutes / 60);
    const stDate = new Date(selectedDate.getTime() + (-timezoneOffsetHours * 60 * 60 * 1000));
    const enDate = new Date(endDate.getTime() + (-timezoneOffsetHours * 60 * 60 * 1000));
  const requestData = {
    userId: id,
    scheduleStartDate: stDate,
    scheduleEndDate: enDate,
      scheduleSlotRequests: Object.entries(scheduleSlots).map(([day, slots]) => {
        const slotDate = new Date(day);
        slotDate.setFullYear(startYear);
        return {
          slotDate: slotDate,
          slotTimes: slots.map((slot) => ({
            slotStart: format(slot.start, "HH:mm:ss"),
            slotEnd: format(slot.end, "HH:mm:ss"),
            active: true,
          })),
        };
      }),
    };
    dispatch(AddSchedules(requestData));
  };

  const addSuccess = () => {
    toast.success("Schedule added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let num = 1;
    dispatch(setSelection(num));
    dispatch(resetAddSchedules());
  };

  const showError = () => {
    toast.error(addSchedule.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let num = 1;
    dispatch(setSelection(num));
    dispatch(resetAddSchedules());
  };
  const handleCancel = () => {
    let num = 1;
    dispatch(setSelection(num));
  };
  const getFilteredEndTimes = (startTime) => {
    if (!startTime) return timeOptions;
    const startDate = new Date(startTime);
    return timeOptions.filter((time) => {
      const timeDate = new Date(time);
      const duration = (timeDate - startDate) / (1000 * 60);
      return duration >= 30
    });
  };

  const getFilteredStartTimes = () => {
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    const currentTime = new Date();
    const filteredTimes = [];

    if (selectedDay.getTime() === currentDate.getTime()) {
        const oneHourAhead = new Date(currentTime.getTime() + 60 * 60 * 1000);
        for (const time of timeOptions) {
            if (time >= oneHourAhead) {
                filteredTimes.push(time);
            }
        }
    } else {

        filteredTimes.push(...timeOptions);
    }

    return filteredTimes;
};
  return (
    <div className="min-h-[600px] max-h-auto self-stretch flex flex-col items-end justify-start gap-[50px] max-w-full min-w-full text-left text-gray-800 font-sans mq750:gap-[20px]">
      <div className="self-stretch flex flex-row items-start justify-start max-w-full text-13xl text-neutral-10-1000">
        <div className="w-full flex flex-col items-start justify-start gap-[8px] max-w-full">
          <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Add Schedule
          </h1>
          <div className="self-stretch relative text-sm leading-[22px] min-w-full">
            You can add schedule up to 2 weeks which will be renewed after your
            confirmation at the end of the current schedule.
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className="min-w-[400px]"
            label="Week 1 starting from"
            value={selectedDate}
            onChange={handleDateChange}
            minDate={new Date()}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errorMessage}
                helperText={errorMessage}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className="min-w-[400px]"
            label="Week 2 ending at"
            value={endDate}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full flex justify-end">
        <ToggleButtonGroup
          className="min-w-[400px]"
          value={toggleWeek}
          exclusive
          onChange={handleToggle}
          aria-label="week toggle"
        >
          <ToggleButton
            value="week1"
            aria-label="week 1"
            className="min-w-[200px] rounded-full"
          >
            Week 1
          </ToggleButton>
          <ToggleButton
            value="week2"
            aria-label="week 2"
            className="min-w-[200px] rounded-full"
          >
            Week 2
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="w-full flex flex-wrap gap-[10px] mt-4">
        {weekDates.map((date, index) => (
          <div key={index} className="flex flex-col items-center">
            <Button
              variant="contained"
              sx={{
                minWidth: "140px",
                boxShadow: "none",
                borderRadius: "50px",
                backgroundColor:
                  selectedDay?.getTime() === date.getTime()
                    ? "#006CF0"
                    : "#EAF2FF",
                color:
                  selectedDay?.getTime() === date.getTime() ? "white" : "black",
                "&:hover": {
                  backgroundColor:
                    selectedDay?.getTime() === date.getTime()
                      ? "#006CF0"
                      : "#EAF2FF",
                  color:
                    selectedDay?.getTime() === date.getTime()
                      ? "white"
                      : "black",
                },
              }}
              onClick={() => handleDaySelect(date)}
            >
              {format(date, "EEEE")}
              <br />
              {format(date, "MMMM d")}
            </Button>
          </div>
        ))}
      </div>
      {selectedDay && (
        <div className="w-full mt-4">
          <div className="flex items-center justify-between w-full">
            <Typography variant="h6">Availability hours</Typography>
            <Button
              onClick={() => handleAddSlot(selectedDayFormatted)}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            />
          </div>
          <div className="flex flex-col gap-4 mt-4 justify-between items-start w-full">
            {scheduleSlots[selectedDayFormatted]?.map((slot, index) => (
              <div key={index} className="flex items-center w-full gap-2">
                <FormControl className="w-[400px]">
                  <InputLabel id={`start-time-label-${index}`}>Start Time</InputLabel>
                  <Select
                    labelId={`start-time-label-${index}`}
                    value={slot.start || ""}
                    input={<OutlinedInput label="Start time" />}
                    onChange={(event) =>
                      handleSlotChange(
                        selectedDayFormatted,
                        index,
                        "start",
                        event.target.value
                      )
                    }
                  >
                    {getFilteredStartTimes(scheduleSlots[selectedDayFormatted], index).map(
                      (time) => (
                        <MenuItem key={time} value={time}>
                          {formatTime12h(time)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl className="w-[400px]">
                  <InputLabel id={`end-time-label-${index}`}>End Time</InputLabel>
                  <Select
                    labelId={`end-time-label-${index}`}
                    value={slot.end || ""}
                    input={<OutlinedInput label="End time" />}
                    onChange={(event) =>
                      handleSlotChange(
                        selectedDayFormatted,
                        index,
                        "end",
                        event.target.value
                      )
                    }
                    disabled={!slot.start}
                  >
                    {slot.start &&
                      getFilteredEndTimes(slot.start).map((time) => (
                        <MenuItem key={time} value={time}>
                          {formatTime12h(time)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <IconButton
                  onClick={() => handleRemoveSlot(selectedDayFormatted, index)}
                >
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="w-full flex items-center">
        {errorMessage && (
          <Typography color="error" variant="body2" className="mt-2">
            {errorMessage}
          </Typography>
        )}
      </div>
      <div className="w-[354px] h-[100px] flex flex-row items-start justify-start py-0 pr-0 pt-12 pl-5 box-border gap-[14px] max-w-full">
        <Button
          className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "18",
            background: "#fff",
            border: "#d9d9d9 solid 1px",
            borderRadius: "4px",
            "&:hover": { background: "#fff" },
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "18",
            background: "#006a6a",
            border: "#006a6a solid 1px",
            borderRadius: "4px",
            "&:hover": { background: "#006a6a" },
          }}
          onClick={handleSave}
        >
          {addSchedule === "isLoading" ? (
            <div>
              <Loader />
            </div>
          ) : addSchedule.status === true ? (
            addSuccess()
          ) : addSchedule.status === false ? (
            showError()
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddSchedule;