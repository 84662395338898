import React from 'react';
import AppointmentsHeader from '../AppointmentHeader';

const Appointment = ({practitioner}) => {
  return (
    <div className=" flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
    <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
      <AppointmentsHeader services={practitioner.result[0].service}></AppointmentsHeader>
    </div>
  </div>
  );
};

export default Appointment;
