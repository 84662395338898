import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../SubComponent/Loader";
import { cancelBookingConsumer, resetCancelBookingConsumer } from "../../Actions/Consumer/ConsumerBookingAction";
import { useNavigate } from 'react-router-dom';


const AppointmentCardUpcomingConsumer = ({ booking, onBookingUpdate }) => {
  const {
    fullName,
    serviceName,
    bookingStartTime,
    bookingEndTime,
    bookingType,
  } = booking;
  const dispatch = useDispatch();
  const BookingId = booking.id;
  const cancelStatus = useSelector((state) => state.cancelBookingConsumer.cancelBooking);

  const formattedStartDate = new Date(bookingStartTime).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  });
  const formattedStartTime = new Date(bookingStartTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  });
  const formattedEndTime = new Date(bookingEndTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  });
  const startTime = new Date(bookingStartTime);
  const endTime = new Date(bookingEndTime);
  const durationInMilliseconds = endTime - startTime;
  const durationInMinutes = durationInMilliseconds / (1000 * 60);
  const [showModal, setShowModal] = useState(false);
  const navigate =useNavigate();
  const handleCancel = () => {
    setShowModal(true);
  };
  const handleChatWithPractitioner = () => {
      
    navigate("/consumer-inbox",
     );
  }
  const handleConfirmCancel = () => {
    dispatch(cancelBookingConsumer(BookingId));
    setShowModal(false);
    onBookingUpdate();
   
  };
  React.useEffect(() => {
    if (cancelStatus.message === "Record fetched successfully") {
     
      dispatch(resetCancelBookingConsumer());

    }
  }, [cancelStatus]);

  return (
    <div className="w-full rounded-xl bg-color-gray-10 box-border flex flex-col items-end justify-center p-6 gap-[16px] min-w-[350px] max-w-[350px] shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-center gap-[6px]">
            <img
              className="w-6 relative h-6"
              alt="Clock"
              src="/vuesaxlinearclock-1.svg"
            />
            <div className="relative leading-[22px] font-semibold text-lg">{formattedStartTime}</div>
            <div className="relative leading-[22px] font-semibold text-lg">{`TO `}</div>
            <div className="relative leading-[22px] font-semibold text-lg">{formattedEndTime}</div>
          </div>
          {booking.bookingTag == 1 && (
            <div
              className={"flex relative leading-[24px] text-sm font-semibold justify-center items-center pt-[3px] pb-[3px] pl-[8px] pr-[8px] text-[#FA8C16] bg-otf "}
            >
              OTF
            </div>
          )}
        </div>

        <div className="flex-1 relative leading-[24px] text-sm">
          {formattedStartDate}
        </div>

        <div className="self-stretch shadow-[0px_-1px_0px_#f0f0f0_inset] bg-color-gray-10 flex flex-row items-center justify-between pt-0 px-0 pb-4">
          <img
            className="w-12 relative rounded-[50%] h-12 object-cover"
            alt="User"
            src={booking.userImages ? booking.userImages.profileImage ? booking.userImages.profileImage : "/Placeholder-profile.png" : "/Placeholder-profile.png"}
          />
          <div className="flex-1 flex flex-row items-start justify-center gap-[8px] pl-4">
            <div className="flex-1 flex flex-col items-center justify-center">
              <div className="self-stretch relative leading-[30px] overflow-hidden whitespace-nowrap font-semibold">
                {serviceName}
              </div>
              <div className="self-stretch relative text-base leading-[24px] overflow-hidden text-ellipsis whitespace-nowrap">
                {fullName}
              </div>
            </div>
          </div>

          <div className={"flex relative leading-[24px] text-xs justify-center items-center pt-[3px] pb-[3px] pl-[8px] pr-[8px] rounded-xl text-white bg-confirmed"}>
            Confirmed
          </div>

        </div>

        <div className="self-stretch flex flex-row items-center justify-between text-sm">
          <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
            <img
              className="w-4 relative h-4 overflow-hidden shrink-0"
              alt="Mode"
              src="/timelapse.svg"
            />
            <div className="relative leading-[22px]">
              {durationInMinutes + " min"}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
            <img
              className="w-4 relative h-4 overflow-hidden shrink-0"
              alt="Mode"
              src="/icons-1.svg"
            />
            <div className="relative leading-[22px]">
              {bookingType === 0 ? "Online" : "On-Site"}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center text-center pt-2 text-sm text-vendor-colors-monochromatic font-heading-h5">
        <div className="flex-1 relative leading-[20px] font-medium text-red-600 cursor-pointer"
          onClick={() => handleCancel()}>
          {"Cancel Booking"}
        </div>
      </div>
      {showModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="relative bg-white p-8 rounded-lg shadow-lg w-[400px] md:w-[500px]">
      
      <button
        className="absolute top-3 right-3 text-gray-600 hover:text-gray-700"
        onClick={() => setShowModal(false)}
      >
        ✕
      </button>

      <p className="text-lg font-semibold text-center mb-4">
        Are you sure you want to cancel?
      </p>
      <div className="flex justify-center space-x-4 mt-6">
        <button
         className="px-6 py-2 font-bold0 rounded-lg w-[120px]"
         style={{
          backgroundColor: '#04A3A3', 
          color: 'white', 
          border: 'none' ,
          borderRadius:'8px'
        }}
          onClick={handleConfirmCancel}
        >
          Yes
        </button>
        <button
        className="px-6 py-2 font-bold rounded-lg w-[120px]"
        style={{
          color: '#04A3A3',
          backgroundColor: 'transparent', 
          border: '1px solid #04A3A3',
          borderRadius:'8px' 
        }}
          onClick={() => handleChatWithPractitioner()}
        >
          Chat Now
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

AppointmentCardUpcomingConsumer.propTypes = {
  booking: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    bookingStartTime: PropTypes.string.isRequired,
    bookingEndTime: PropTypes.string.isRequired,
    bookingType: PropTypes.number.isRequired,
  }).isRequired,

};

export default AppointmentCardUpcomingConsumer;