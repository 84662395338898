import {
  Select,
  InputLabel,
  Menu,
  MenuItem,
  IconButton,
  FormHelperText,
  FormControl,
  InputAdornment,
  TextField,
  Fade,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConsumerHeader from "../components/Layout/ConsumerHeader";

const ConsumerPreferences = () => {
  const navigate = useNavigate();
  const handleNo = () => {
    navigate("/search-results-practitioners");
  };
  const handleContinue = () => {
    navigate("/consumer-preferences-02")
  }
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[121px] box-border gap-[56px] tracking-[normal] leading-[normal] mq750:gap-[28px]">
      <ConsumerHeader></ConsumerHeader>
      <main className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <section className="w-[1200px] flex flex-col items-start justify-start gap-[40px] max-w-full text-center text-29xl text-gray-800 font-web-primary-h3-primary-dm-sans mq750:gap-[20px]">
          <div className="self-stretch h-[424px] relative rounded-2xl bg-[url('/public/imageprefernces@2x.png')] bg-cover bg-no-repeat bg-[top]">
            <img
              className="absolute top-[0px] left-[calc(50%_-_600px)] rounded-2xl w-full h-full object-cover hidden"
              alt=""
              src="/imageprefernces@2x.png"
            />
          </div>
          <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <div className="flex flex-col items-center justify-start gap-[24px] max-w-full">
              <h1 className="m-0 relative text-inherit leading-[64px] font-bold font-inherit mq450:text-10xl mq450:leading-[38px] mq1050:text-19xl mq1050:leading-[51px]">
                Let’s design your unique Experience
              </h1>
              <div className="w-[588px] relative text-base leading-[24px] flex items-center justify-center max-w-full">
                The more we get to know you, the more we can serve up classes
                you love. Help us personalize your practice by answering three
                quick questions.
              </div>
              <div className="w-[180px] h-[114px] flex flex-col items-center justify-start gap-[16px]">
                <Button
                  className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                  variant="contained"
                  onClick={handleContinue}
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    fontSize: "18",
                    background: "#f28500",
                    border: "#f28500 solid 1px",
                    borderRadius: "44px",
                    "&:hover": { background: "#f28500" },
                  }}
                >
                  Sounds Good
                </Button>
                <Button
                  disableElevation={true}
                  variant="text"
                  sx={{ borderRadius: "0px 0px 0px 0px" }}
                  onClick={handleNo}
                >
                  No Thanks
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ConsumerPreferences;
