
import {GET_SERVICES_FAIL, GET_SERVICES_PENDING, GET_SERVICES_SUCCESS} from "../../Actions/Types";
    const initialState = {
        getServices: [],
    };
    const get_popular_services = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_SERVICES_SUCCESS:
          return {
            ...state,
            getServices: payload.getServices,
          };
        case GET_SERVICES_PENDING:
          return {
            ...state,
            getServices: payload.getServices,
          };
      case GET_SERVICES_FAIL:
        return {
          ...state,
          getServices: [],
        };
        default:
          return state;
      }
    };
    export default get_popular_services;