
import { getSchedules } from "../../Actions/Practitioner/getSchedulesAction";
import {GET_SCHEDULE_SUCCESS, GET_SCHEDULE_PENDING, GET_SCHEDULE_FAIL, SET_SCHEDULE, RESET_SCHEDULE } from "../../Actions/Types"
    const initialState = {
        getSchedules: [],
        selection: {}
    };
    const getSchedulesReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_SCHEDULE_SUCCESS:
          return {
            ...state,
            getSchedules: payload.getSchedules,
          };
        case GET_SCHEDULE_PENDING:
          return {
            ...state,
            getSchedules: payload.getSchedules,
          };
        case GET_SCHEDULE_FAIL:
        return {
          ...state,
          getSchedules: initialState.getSchedules,
        };
        case SET_SCHEDULE:
        return {
          ...state,
          selection: payload.selection,
        };
        case RESET_SCHEDULE:
        return {
          ...state,
          selection: {},
        };
        default:
          return state;
      }
    };
    export default getSchedulesReducer;