import { SET_SIDEBAR_TAB, RESET_SIDEBAR_TAB } from "../../Actions/Types";

const initialState = {
    sideBar: 0,
};
const setSidebarReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SIDEBAR_TAB:
  return {
    ...state,
    sideBar: payload.sideBar, 
  };
  case RESET_SIDEBAR_TAB:
      return {
        ...state,
        sideBar: payload.sideBar,
      };
    default:
      return state;
  }
};
export default setSidebarReducer;