import { SIGNUP_SUCCESS} from "../Types"




export const confirmUser = (body) => async (dispatch) => {
    dispatch({
        type: SIGNUP_SUCCESS,
        payload: {signUp: [], UserData : body },
      })
  };

