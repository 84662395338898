import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const DateOfBirthField = ({ value, onChange }) => {
    const [open, setOpen] = useState(false);
    const formattedDate = value ? value.format('MMMM D, YYYY') : '';
  
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ position: 'relative', width: '100%' }}>
          <TextField
            id="date-of-birth"
            label="Date Of Birth"
            variant="standard"
            className="w-full h-full"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EditCalendarOutlinedIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpen(!open)}
                  />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formattedDate}
            sx={{
              '.MuiInputBase-root': {
                height: '100%',
              },
              '.MuiInputLabel-root': {
                transform: 'translate(0, 1.5px) scale(1)',
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(0, -1.5px) scale(0.75)',
                },
              },
              '.MuiFormControl-root': {
                height: '100%',
              },
            }}
          />
          {open && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                zIndex: 10,
                width: '100%',
              }}
            >
              <DateCalendar
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  setOpen(false);
                }}
                sx={{
                    width: '60%',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    border: "1px"
                  }}
              />
            </div>
          )}
        </div>
      </LocalizationProvider>
    );
  };
  
  export default DateOfBirthField;