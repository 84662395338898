import {SIGNIN_FAIL, SIGNIN_PENDING, SIGNIN_SUCCESS, RESET_SIGNIN_STATE} from "../../Actions/Types";
  const initialState = {
    signIn: [],
    UserData: null,
  };
  const signin_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SIGNIN_SUCCESS:
        return {
          ...state,
          signIn: payload.signIn,
          UserData: payload.UserData,
        };
      case SIGNIN_PENDING:
        return {
          ...state,
          signIn: payload.signIn,
          UserData: null,
        };
      case SIGNIN_FAIL:
        return {
          ...state,
          signIn: payload.signIn,
          UserData: payload.UserData,

        };
        case RESET_SIGNIN_STATE:
      return {
        ...state,
        signIn: initialState.signIn, 
        UserData: initialState.UserData, 
      };
      default:
        return state;
    }
  };
  export default signin_reducer;