import React from "react";
import { createRoot } from "react-dom/client";
import { Provider, useDispatch } from "react-redux";
import Store from "./Store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";

import "./global.css";
import { handleNotificationReceived } from './NotificationHandler';

const muiTheme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>,
);
reportWebVitals();
// service worker

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js', { scope: '/' })
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });

  navigator.serviceWorker.ready.then((registration) => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      // console.log('Message received from service worker:', event);
      if (event.data && event.data.type === 'notification_received') {
        // console.log('Notification received in main application:', event.data.payload);
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData && userData.user && userData.user.id) {
          handleNotificationReceived(userData.user.id);
        }
      }
    });
  });
}

