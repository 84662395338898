import {GET_PRACTITIONER_ID_FAIL, GET_PRACTITIONER_ID_PENDING, GET_PRACTITIONER_ID_SUCCESS, SET_MESSAGE} from "../Types"
import ConsumerService from '../../services/ConumerService';




  export const getPractitioner = (id) => async (dispatch) => {
    dispatch({
      type: GET_PRACTITIONER_ID_PENDING,
      payload: { practitioner: "isLoading" },
    });
    return await ConsumerService.getPractitionerById(id)
      .then(
        (data) => {
          console.log(data);
          if(data.data.status == true)
          {
            dispatch({
                type: GET_PRACTITIONER_ID_SUCCESS,
                payload: { practitioner: data.data,  },
              })
            }
            else
            { dispatch({
                type: GET_PRACTITIONER_ID_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: GET_PRACTITIONER_ID_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });

  };