
import {UPDATE_SERVICE_FAIL, UPDATE_SERVICE_SUCCESS, UPDATE_SERVICE_PENDING, RESET_UPDATE_SERVICE} from "../../Actions/Types";
    const initialState = {
        updateService: "",

    };
    const updateServiceReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case UPDATE_SERVICE_SUCCESS:
          return {
            ...state,
            updateService: payload.updateService,
          };
        case UPDATE_SERVICE_PENDING:
          return {
            ...state,
            updateService: payload.updateService,
          };
        case UPDATE_SERVICE_FAIL:
        return {
          ...state,
        };
        case RESET_UPDATE_SERVICE:
      return {
        ...state,
        updateService: "", 
      };
        default:
          return state;
      }
    };
    export default updateServiceReducer;