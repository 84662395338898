import React from 'react';
import Button from '@mui/material/Button';
import ServiceTable from '../../SubComponent/ServiceTable';

const Services = ({ practitioner, handleAdd }) => {
  return (
    <>
  <div className="flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[8px] min-w-[475px] max-w-full mq750:min-w-full">
                      <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                        Services
                      </h1>
                      <div className="w-[444px] relative text-sm leading-[22px] inline-block max-w-full">
                        Manage all your services here
                      </div>
                    </div>
                    <Button
                      className="h-[34px] w-[177px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                      onClick={handleAdd}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "14",
                        background: "#006a6a",
                        border: "#006a6a solid 1px",
                        borderRadius: "4px",
                        "&:hover": { background: "#006a6a" },
                        width: 177,
                        height: 34,
                      }}
                    >
                      Add new Service
                    </Button>
                  </div>
                  {practitioner.result[0].service.length === 0 ? (
                    <div className="flex items-center justify-center font-bold font-inherit text-xl text-m3-black h-[400px] w-[1000px]">
                      <div>
                        <img src="no_services.svg" alt="" className="mx-auto pl-8" />
                        <br></br>
                        No services available
                      </div>
                    </div>
                  ) : (
                    <ServiceTable services={practitioner.result[0].service} />
                  )}
                </div>
    </>
  );
};

export default Services;
