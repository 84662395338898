import {CONFIRM_SIGNUP_FAIL, CONFIRM_SIGNUP_PENDING, CONFIRM_SIGNUP_SUCCESS, RESET_CONFIRM_STATE} from "../../Actions/Types";
  const initialState = {
    confirmSignUp: [],
    UserData: null,
  };
  const confirm_signup_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CONFIRM_SIGNUP_SUCCESS:
        return {
          ...state,
          confirmSignUp: payload.confirmSignUp,
          UserData: payload.UserData,
        };
      case CONFIRM_SIGNUP_PENDING:
        return {
          ...state,
          confirmSignUp: payload.confirmSignUp,
          UserData: null,
        };
      case CONFIRM_SIGNUP_FAIL:
        return {
          ...state,
          confirmSignUp: [],
          UserData: null,

        };
        case RESET_CONFIRM_STATE:
      return {
        ...state,
        confirmSignUp: initialState.confirmSignUp, 
        UserData: initialState.UserData, 
      };
      default:
        return state;
    }
  };
  export default confirm_signup_reducer;