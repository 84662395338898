import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../SubComponent/Loader";
import { acceptBooking, resetAcceptBooking, rejectBooking, resetRejectBooking } from "../../Actions/Practitioner/PractitionerBookingAction";
import { setOtherUserDetail } from "../../Actions/Practitioner/addServiceAction";
import { setSelection } from "../../Actions/Practitioner/addServiceAction";
import { useNavigate } from "react-router-dom";


const AppointmentCardPending = ({ booking, onBookingUpdate }) => {
  const navigate = useNavigate();
  const {
    fullName,
    serviceName,
    bookingStartTime,
    bookingEndTime,
    bookingStatus,
    bookingTag,
    bookingType,
  } = booking;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const BookingId = booking.id;
  const acceptStatus = useSelector((state) => state.cancelBooking.acceptBooking);
  const rejectStatus = useSelector((state) => state.cancelBooking.rejectBooking);



  const formattedStartDate = new Date(bookingStartTime).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  });
  const formattedStartTime = new Date(bookingStartTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  });
  const formattedEndTime = new Date(bookingEndTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  });
  const handleAccept = () => {
    dispatch(acceptBooking(BookingId));
    onBookingUpdate();
  };

  const handleReject = () => {
    dispatch(rejectBooking(BookingId));
    onBookingUpdate();
    setShowModal(false);
  };
 
 
  const handleChatNow = () => {
    if (booking) {
      const consumerDetail = {
        id: booking.consumerId,
        img: booking.userImages?.profileImage || null, 
        name: booking.fullName,
      };
      const num = 4;
      dispatch(setOtherUserDetail(consumerDetail));
      dispatch(setSelection(num));
  
      navigate('/inbox');
    }
  };
  
  React.useEffect(() => {
    if (acceptStatus.message === "Record fetched successfully" || rejectStatus.message === "Record fetched successfully") {
      onBookingUpdate();
      dispatch(resetAcceptBooking());
      dispatch(resetRejectBooking());

    }
  }, [acceptStatus, rejectStatus, onBookingUpdate]);

  return (
    <div className="w-[350px] rounded-xl bg-color-gray-10 box-border flex flex-col items-end justify-center p-6 gap-[16px] min-w-[350px] max-w-[350px] shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)]">
      <div className="self-stretch flex flex-col items-center justify-center gap-[16px]">

        <div className="self-stretch flex flex-col items-center justify-center gap-[16px]r">
          <div className="self-stretch flex flex-row items-center justify-center gap-[8px]">
            <div className="flex-1 relative leading-[24px] font-semibold">
              {formattedStartDate}
            </div>
            <div
              className={"flex relative leading-[24px] text-xs justify-center items-center pt-[3px] pb-[3px] pl-[8px] pr-[8px] rounded-xl text-white bg-pending"}
            >
              Pending
            </div>
            {booking.bookingTag == 1 && (
              <div
                className={"flex relative leading-[24px] text-sm font-semibold justify-center items-center pt-[3px] pb-[3px] pl-[8px] pr-[8px] text-[#FA8C16] bg-otf "}
              >
                OTF
              </div>
            )}

          </div>
        </div>

        <div className="self-stretch shadow-[0px_-1px_0px_#f0f0f0_inset] bg-color-gray-10 flex flex-row items-center justify-between pt-0 px-0 pb-4">
          <div className="flex-1 flex flex-row items-start justify-center gap-[8px]">
            <div className="flex-1 flex flex-col items-center justify-center">
              <div className="self-stretch relative leading-[30px] overflow-hidden whitespace-nowrap font-semibold">
                {fullName}
              </div>
              <div className="self-stretch relative text-base leading-[24px] overflow-hidden text-ellipsis whitespace-nowrap">
                {serviceName}
              </div>
            </div>
          </div>
          <img
            className="w-12 relative rounded-[50%] h-12 object-cover"
            alt="User"
            src={booking.userImages ? booking.userImages.profileImage ? booking.userImages.profileImage : "/Placeholder-profile.png" : "/Placeholder-profile.png"}
          />
        </div>

        <div className="self-stretch flex flex-row items-center justify-between text-sm">
          <div className="flex flex-row items-center justify-start gap-[6px]">
            <img
              className="w-4 relative h-4"
              alt="Clock"
              src="/vuesaxlinearclock-1.svg"
            />
            <div className="relative leading-[22px]">{formattedStartTime}</div>
            <div className="relative leading-[22px]">{`to `}</div>
            <div className="relative leading-[22px]">{formattedEndTime}</div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
            <img
              className="w-4 relative h-4 overflow-hidden shrink-0"
              alt="Mode"
              src="/icons-1.svg"
            />
            <div className="relative leading-[22px]">
              {bookingType === 0 ? "Online" : "On-Site"}
            </div>
          </div>
        </div>
      </div>


      <div className="self-stretch flex flex-row items-center justify-center text-center text-sm text-vendor-colors-monochromatic font-heading-h5">
        {bookingTag !== 1 && (
          <>
            <div className="flex-1 relative leading-[20px] font-medium text-green-600 cursor-pointer"
              onClick={() => handleAccept()}>
              {"Accept"}

            </div>
            <img
              className="self-stretch w-0 relative max-h-full object-contain"
              alt=""
              src="/line-7.svg"
            />
            <div className="flex-1 relative leading-[20px] font-medium text-red-600 cursor-pointer"
              onClick={() => setShowModal(true)}>
              {"Reject"}
            </div>
          </>
        )}
        <img
          className="self-stretch w-0 relative max-h-full object-contain"
          alt=""
          src="/line-8.svg"
        />
        <div className="flex-1 relative leading-[20px] font-medium text-vendor-colors-triadic cursor-pointer"
          onClick={() => handleChatNow()}>
          Chat Now
        </div>
      </div>
      {showModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="relative bg-white p-8 rounded-lg shadow-lg w-[400px] md:w-[500px]">
      
      <button
        className="absolute top-3 right-3 text-gray-600 hover:text-gray-700"
        onClick={() => setShowModal(false)}
      >
        ✕
      </button>

      <p className="text-lg font-semibold text-center mb-4">
        Are you sure you want to reject the booking?
      </p>
      <div className="flex justify-center space-x-4 mt-6">
        <button
          className="px-6 py-2 font-bold rounded-lg w-[120px]"
          style={{
            backgroundColor: '#04A3A3', 
            color: 'white',
            border: 'none' ,
            borderRadius:'8px'
          }}
          onClick={handleReject}
        >
          Yes
        </button>
        <button
          className="px-6 py-2 font-bold rounded-lg w-[120px]"
          style={{
            color: '#04A3A3',
            backgroundColor: 'transparent', 
            border: '1px solid #04A3A3',
            borderRadius:'8px' 
          }}
          onClick={handleChatNow}
        >
          Chat Now
        </button>
      </div>
    </div>
  </div>
)}


    </div>
  );
};

AppointmentCardPending.propTypes = {
  booking: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    bookingStartTime: PropTypes.string.isRequired,
    bookingEndTime: PropTypes.string.isRequired,
    bookingStatus: PropTypes.number.isRequired,
    bookingType: PropTypes.number.isRequired,
  }).isRequired,
};

export default AppointmentCardPending;