import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from "./AuthHeader";
import { interceptor } from "../services/Interceptor";
const API_URL = BASE_URL;
class PractitionerBookingService {
  async cancelBooking(BookingId) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
    return axios.put(
      API_URL + "Booking/CancelAppointment",
      {},
      {
        params: {
          bookingId: BookingId,
        },
        headers: {
          ...authHeader(),
          TimeZoneOffSet: timeZoneOffset,
        },
      }
    );
  }

  async rejectBooking(BookingId) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
    return axios.put(API_URL + "Booking/RejectAppointment", {}, {
      params: { Id: BookingId },
      headers: {
        ...authHeader(),
        TimeZoneOffSet: timeZoneOffset,
      },
    });
  }

  async confirmBooking(BookingId) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
    return axios.put(API_URL + "PractitionerSchedule/ConfirmBookingAppointment", {}, {
      params: { Id: BookingId },
      headers: {
        ...authHeader(),
        TimeZoneOffSet: timeZoneOffset,
      },
    });
  }
}

export default new PractitionerBookingService();
