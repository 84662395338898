import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;
class UpdateProfileService
{
    async updateProfile(body)
    {
        interceptor();
        return axios.put(API_URL + "Practitioner/UpdatePractitionerProfile", body, {
            headers: {
               ...authHeader(),
              'Content-Type': 'multipart/form-data'
            }
          });
    }

}
export default new UpdateProfileService