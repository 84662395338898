
import {GET_ELASTIC_SERVICES_FAIL, GET_ELASTIC_SERVICES_PENDING, GET_ELASTIC_SERVICES_SUCCESS, RESET_ELASTIC_SERVICES} from "../../Actions/Types";
    const initialState = {
        elasticServices: [],
    };
    const getElasticServicesReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_ELASTIC_SERVICES_SUCCESS:
          return {
            ...state,
            elasticServices: payload.elasticServices,
          };
        case GET_ELASTIC_SERVICES_PENDING:
          return {
            ...state,
            elasticServices: payload.elasticServices,
          };
      case GET_ELASTIC_SERVICES_FAIL:
        return {
          ...state,
          elasticServices: [],
        };
        case RESET_ELASTIC_SERVICES:
            return {
                ...state,
                elasticServices: [],
            }
        default:
          return state;
      }
    };
    export default getElasticServicesReducer;