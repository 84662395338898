
import {GET_ELASTIC_PRACTITIONERS_FAIL, GET_ELASTIC_PRACTITIONERS_PENDING, GET_ELASTIC_PRACTITIONERS_SUCCESS, RESET_ELASTIC_PRACTITIONERS} from "../../Actions/Types";
    const initialState = {
        elasticPractitioners: [],
    };
    const getElasticPractitionersReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_ELASTIC_PRACTITIONERS_SUCCESS:
          return {
            ...state,
            elasticPractitioners: payload.elasticPractitioners,
          };
        case GET_ELASTIC_PRACTITIONERS_PENDING:
          return {
            ...state,
            elasticPractitioners: payload.elasticPractitioners,
          };
      case GET_ELASTIC_PRACTITIONERS_FAIL:
        return {
          ...state,
          elasticPractitioners: [],
        };
        case RESET_ELASTIC_PRACTITIONERS:
            return {
                ...state,
                elasticPractitioners: [],
            }
        default:
          return state;
      }
    };
    export default getElasticPractitionersReducer;