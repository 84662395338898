import {UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_PENDING, UPDATE_PROFILE_FAIL} from "../../Actions/Types"
    const initialState = {
        updateProfileConsumer: ""
    };
    const updateProfileConsumerReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case UPDATE_PROFILE_SUCCESS:
          return {
            ...state,
            updateProfileConsumer: payload.updateProfileConsumer,
          };
        case UPDATE_PROFILE_PENDING:
          return {
            ...state,
            updateProfileConsumer: payload.updateProfileConsumer,
          };
        case UPDATE_PROFILE_FAIL:
        return {
          ...state,
        };

        default:
          return state;
      }
    };
    export default updateProfileConsumerReducer;