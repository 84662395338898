import {RESEND_FAIL, RESEND_PENDING, RESEND_SUCCESS} from "../../Actions/Types";
  const initialState = {
    resendOtp: [],
    UserData: null,
  };
  const resendotp_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case RESEND_SUCCESS:
        return {
          ...state,
          resendOtp: payload.resendOtp,
        };
      case RESEND_PENDING:
        return {
          ...state,
          resendOtp: payload.resendOtp,
        };
      case RESEND_FAIL:
        return {
          ...state,
          resendOtp: [],
        };
      default:
        return state;
    }
  };
  export default resendotp_reducer;