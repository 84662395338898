import React from 'react';
import Button from '@mui/material/Button';

const OtfBox = ({ status, bookingType, startTime, endTime, title, expiry, isConsumer, onAccept, onReject }) => {
  const currentDate = new Date();
  const expiryDate = new Date(expiry);
  const isExpired = currentDate > expiryDate;

  return (
    <div className="w-[343px] h-[150px] p-[16px] bg-[#EAF2FF] rounded-[16px] flex flex-col justify-center items-center">
      

      <div className="text-lg font-semibold text-center">
        {title}
      </div>

     
      <div className="text-sm text-center">
        {new Date(startTime).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} -
        {new Date(startTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })} -
        {new Date(endTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })} -
        {bookingType}
      </div>
   {console.log(isConsumer,'consumer')
   }
      {isExpired ? (
        isConsumer ? (
          <Button
            style={{
              flex: 1,
              position: 'relative',
              lineHeight: '20px',
              fontWeight: '500',
              color: 'grey',
              boxShadow: 'none',
              cursor: 'not-allowed',
            }}
            variant="text"
            size="small"
            disabled
          >
            Expired
          </Button>
        ) : (
          <div className="text-xs mt-4 text-gray-700">
            Invitation has been Expired
          </div>
        )
      ) : isConsumer ? (
        status === 'pending' ? (
          <div className="flex gap-4 mt-4">
            <Button
              style={{
                flex: 1,
                position: 'relative',
                lineHeight: '20px',
                fontWeight: '500',
                color: 'green',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }}
              variant="text"
              size="small"
              onClick={onAccept}
            >
              Accept
            </Button>
            <Button
              style={{
                flex: 1,
                position: 'relative',
                lineHeight: '20px',
                fontWeight: '500',
                color: 'red',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                cursor: 'pointer',
              }}
              variant="text"
              size="small"
              onClick={onReject}
            >
              Reject
            </Button>
          </div>
        ) : (
          <Button
            style={{
              flex: 1,
              position: 'relative',
              lineHeight: '20px',
              fontWeight: '500',
              color: 'gray',
              backgroundColor: 'transparent',
              borderRadius: '8px',
              boxShadow: 'none',
              cursor: 'not-allowed',
            }}
            variant="text"
            size="small"
            disabled
          >
            {status === 'accepted' ? 'Accepted' : 'Rejected'}
          </Button>
        )
      ) : (
        <div className={`text-xs mt-4 ${getStatusStyle(status)}`}>
          {getStatusText(status)}
        </div>
      )}
    </div>
  );
};

const getStatusText = (status) => {
  switch (status) {
    case 'pending':
      return 'Booking invite has been sent, Pending';
    case 'accepted':
      return 'Booking invite has been Accepted';
    case 'rejected':
      return 'Booking invite has been Rejected';
    default:
      return 'Unknown Status';
  }
};

const getStatusStyle = (status) => {
  switch (status) {
    case 'pending':
      return 'text-blue-500';
    case 'accepted':
      return 'text-green-500';
    case 'rejected':
      return 'text-red-500';
    default:
      return 'text-gray-500';
  }
};

export default OtfBox;
