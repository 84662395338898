import { useCallback, useState, useEffect } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../components/SubComponent/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getPractitioner } from "../Actions/Consumer/GetPractitionerAction";
import { getFeaturedPractitioners } from "../Actions/Consumer/GetFeaturedPractitonerAction";
import { Button as BaseButton, buttonClasses } from "@mui/base/Button";
import CMPBusinessCard from "../components/SubComponent/ButtonBusinessCard";
import ServiceCard from "../components/SubComponent/ServiceCard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SimilarServiceCard from "../components/SubComponent/similarServiceCard";
import SpaIcon from '@mui/icons-material/Spa';
import axios from "axios";
import { BASE_URL } from "../Constants";


const PractitionerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [address, setAddress] = useState("N/A");
  const [description, setDescription] = useState("N/A");
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const allPractitioners = useSelector(
    (state) => state.getPractitioners.getPractitioners
  );
  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages.length);
  };
  const [id, setId] = useState(location.state.id);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 3;
  const practitioner = useSelector((state) => state.practitioner.practitioner);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getPractitioner(id));
    dispatch(getFeaturedPractitioners(pageNumber, pageSize));
  }, [id, pageNumber, pageSize, dispatch]);

  const [allImages, setAllImages] = useState([]);
  const onTabContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='infoContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onTabContainer1Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='servicesContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    setAllImages([]);
    if (practitioner.status === true) {
      if (practitioner.result[0].userImages) {
        const profileImage = practitioner.result[0].userImages.profileImage;
        const images = practitioner.result[0].userImages.images.split("|");
        const allImagesArray = [profileImage, ...images];
        setAllImages(allImagesArray);
      }
      setCategories(practitioner.result[0].categories);
      if (practitioner.result[0].categoryTags) {
        const allTags = practitioner.result[0].categoryTags.flatMap(
          (categoryTag) => categoryTag.tags.split("|")
        );
        setTags(allTags);
      }
      
      if (practitioner.result[0].bussinessDetail) {
        if (practitioner.result[0].bussinessDetail.address) {
          setAddress(practitioner.result[0].bussinessDetail.address);
        }
        if (practitioner.result[0].bussinessDetail.description) {
          setDescription(practitioner.result[0].bussinessDetail.description);
        }
      }
    }
  }, [practitioner]);
  const handleCardClick2 = (id) => {
    let service;
    axios.get(BASE_URL + "Consumer/GetServiceDetails", {
      params: {
        serviceId: id
      }
    })
      .then(response => {
        service = response.data.result[0];
        navigate('/service-details', { state: { service } });
      })
      .catch(error => {

        console.error('Error fetching service details:', error);
      });

  };
  const handleCardClick = (pracId) => {
    setId(pracId);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };
  const handleChatNow = () => {
    const user = JSON.parse(localStorage.getItem("user")); 
  
    if (!user) {
      navigate("/consumer-sign-in"); 
      return;
    }
  
    if (practitioner && practitioner !== "isLoading" && practitioner.result[0]) {
      const practitionerData = practitioner.result[0];
      const practitionerDetails = {
        id: practitioner.result[0].id,
        img: (practitionerData.userImages && practitionerData.userImages.profileImage) || null,
        name: `${practitioner.result[0].firstName} ${practitioner.result[0].lastName}`,
      };
      console.log('Practitioner Details:', practitionerDetails);
  
      navigate("/consumer-inbox", {
        state: {
          inbox: 2,
          otherUserDetail: practitionerDetails,
        },
      });
    }
  };
  
  
  return (
    <div className="w-full relative bg-neutral-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-10 box-border gap-[80px] tracking-[normal] leading-[normal] mq450:gap-[20px] mq750:gap-[40px]">
      <ConsumerHeader></ConsumerHeader>
      {practitioner === "isLoading" ? (
        <div className="flex items-center justify-center min-h-full min-w-full">
          <Loader />
        </div>
      ) : (
        practitioner.status === true && (
          <main className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
            <section className="w-[1200px] flex flex-col items-start justify-start gap-[60px] max-w-full text-left text-base text-m3-black font-web-primary-h3-primary-dm-sans lg:gap-[60px] mq750:gap-[30px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                <div className="self-stretch flex flex-row items-start justify-start gap-[80px] max-w-full lg:gap-[40px] mq750:gap-[20px] mq1050:flex-wrap">
                  <div className="flex-1 rounded-3xl overflow-hidden flex flex-row items-start justify-start min-w-[469px] max-w-full mq750:min-w-full">
                    <div className="h-[520.1px] flex-1 relative max-w-full">
                      <img
                        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                        alt="Practitioner"
                        src={
                          allImages.length > 0
                            ? allImages[currentIndex]
                            : "/Placeholder-profile.png"
                        }
                      />
                      <img
                        className="absolute top-[calc(50%_-_20.05px)] right-[32px] rounded-lg w-10 h-10 object-contain z-[1]"
                        loading="lazy"
                        alt="Next"
                        src="/button@2x.png"
                        onClick={handleNextImage}
                      />
                    </div>
                  </div>
                  <div className="w-[398px] overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[16px] min-w-[398px] max-w-full mq750:min-w-full mq1050:flex-1">
                    <iframe className="[border:none] self-stretch h-[284.1px] relative bg-[url('/public/basemap-image@3x.png')] bg-cover bg-no-repeat bg-[top]" />
                    <div className="self-stretch flex flex-row items-start justify-start p-2 box-border gap-[15px] max-w-full mq450:flex-wrap">
                      <div className="bg-hitbox overflow-hidden flex flex-col items-center justify-center">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0"
                          loading="lazy"
                          alt=""
                          src="/directions.svg"
                        />
                      </div>
                      <div className="flex-1 relative leading-[24px] inline-block min-w-[223px] max-w-full">
                        {address}
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start p-2 box-border gap-[15px] max-w-full mq450:flex-wrap">
                      <div className="bg-hitbox overflow-hidden flex flex-col items-center justify-center">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0"
                          loading="lazy"
                          alt=""
                          src="/phone.svg"
                        />
                      </div>
                      <div className="flex-1 relative leading-[24px] inline-block min-w-[81px] whitespace-nowrap max-w-full">
                        {practitioner.result[0].phoneNumber
                          ? practitioner.result[0].phoneNumber
                          : "N/A"}
                      </div>
                    </div>
                    <div className="self-stretch hidden flex-row items-start justify-start p-2 box-border gap-[15px] max-w-full mq450:flex-wrap">
                      <div className="bg-hitbox overflow-hidden flex flex-col items-center justify-center">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0"
                          alt=""
                          src="/star-border.svg"
                        />
                      </div>
                      <div className="self-stretch flex-1 relative leading-[24px] inline-block min-w-[223px] max-w-full">
                        4.9 - 500+ ratings
                      </div>
                    </div>
                    <Button
                      className="self-stretch h-[52px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
                      startIcon={
                        <img width="24px" height="24px" src="/wrapper.png" />
                      }
                      onClick={handleChatNow}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "18",
                        background: "#f28500",
                        border: "#f28500 solid 1px",
                        borderRadius: "32px",
                        "&:hover": { background: "#f28500" },
                        height: 52,
                      }}
                    >
                      Chat Now
                    </Button>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start  font-h5-medium">
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<InfoOutlinedIcon />}
                        label="Info"
                        aria-controls="tabpanel-0"
                        id="tab-0"
                        iconPosition="start"
                      />
                      <Tab
                        icon={<SpaIcon></SpaIcon>}
                        label="Services"
                        aria-controls="tabpanel-1"
                        id="tab-1"
                        iconPosition="start"
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <div
                        className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-full"
                        data-scroll-to="infoContainer"
                      >
                        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[24px] max-w-full">
                          <img
                            className="h-20 w-20 relative rounded-[50%] object-cover min-h-[80px]"
                            loading="lazy"
                            alt=""
                            src={
                              practitioner.result[0].userImages
                                ? practitioner.result[0].userImages.profileImage
                                : "/Placeholder-images.png"
                            }
                          />
                          <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[402px] max-w-full mq750:min-w-full">
                            <b className="relative leading-[28px]">
                              {practitioner.result[0].firstName +
                                " " +
                                practitioner.result[0].lastName}
                            </b>
                            <div className="self-stretch relative text-sm leading-[22px] text-xl">
                              {categories.map((category, index) => (
                                <span key={index}>
                                  {category.name}
                                  {index < categories.length - 1 && " | "}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full">
                          <b className="relative leading-[28px]">
                            About the practitioner
                          </b>
                          <div className="self-stretch flex flex-row items-start justify-start max-w-full text-base">
                            <div className="flex-1 relative leading-[24px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:4] [-webkit-box-orient:vertical] max-w-full">
                              {description}
                            </div>
                          </div>
                        </div>
                        <b className="relative leading-[28px]">
                          General Availability
                        </b>
                        <div className="flex flex-row flex-wrap items-start justify-start py-0 pr-[392px] pl-0 gap-[16px mq450:pr-5 mq450:box-border mq750:pr-[196px] mq750:box-border">
                          9AM - 5PM
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div className="w-full flex flex-col items-start justify-start gap-[40px] max-w-full text-lg mq750:gap-[20px]">
                        <div className="self-stretch relative text-base leading-[24px] font-semibold text-m3-black inline-block min-w-[70px]">
                          Services
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start gap-[24px] max-w-[312px] text-5xl text-neutral-11 mq750:max-w-full">
                          {practitioner.result[0].service
                            .filter((srv) => srv.isActive)
                            .map((srv) => (
                              <div className="cursor-pointer rounded-xl" onClick={() => handleCardClick2(srv.id)}>
                                <SimilarServiceCard
                                  key={srv.id}
                                  data={srv}
                                  practitioner={practitioner.result[0]}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                <b className="relative leading-[28px]">Similar Practitioners</b>
                {allPractitioners === "isLoading" ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <div className="flex flex-wrap items-start justify-start gap-6">
                    {allPractitioners.map((practitioner) => (
                      <div
                        key={practitioner.id}
                        className="cursor-pointer rounded-xl"
                        onClick={() => handleCardClick(practitioner.id)}
                      >
                        <CMPBusinessCard
                          key={practitioner.id}
                          data={practitioner}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className=" items-start justify-start py-0 pl-0 box-border gap-[16px] min-h-[30px] max-w-[200px]">
                {tags.length != 0 && (
                  <Stack direction="row" spacing={1}>
                    {tags.map((tag, index) => (
                      <Chip
                        clickable
                        variant="filled"
                        key={index}
                        color="primary"
                        label={tag.toUpperCase()}
                      />
                    ))}
                  </Stack>
                )}
              </div>
              <footer className="self-stretch flex flex-col items-start justify-start gap-[32px] text-left text-mid text-gray-200 font-web-primary-h3-primary-dm-sans mq750:gap-[16px]">
                <div className="flex flex-row items-start justify-start gap-[54.9px] lg:flex-wrap mq750:gap-[27px]">
                  <div className="w-[248px] flex flex-col items-start justify-start gap-[32px] text-mini text-gray-700">
                    <div className="w-[200px] flex flex-row items-end justify-start gap-[10.5px]">
                    <img
    className="relative overflow-hidden"
    loading="lazy"
    alt="Logo"
    src="/PractLogo.png" 
    style={{ width: '236px', height: '47px' }} 
  />
                    </div>
                    <div className="self-stretch relative tracking-[-0.11px] leading-[26px] mix-blend-normal">
                      Your all-in-one platform for effortless wellness,
                      connecting you with curated fitness, wellness services and
                      personalized recommendations.
                    </div>
                  </div>
                  <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
                    <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                      Company
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      About us
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Contact us
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Privacy Policy
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Terms and Conditions
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-[16px]">
                    <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                      Services
                    </div>
                    <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                      Mind
                    </div>
                    <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                      Body
                    </div>
                    <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                      Medicine
                    </div>
                    <div className="relative tracking-[-0.2px]">
                      Psychologists and Counselors
                    </div>
                  </div>
                  <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
                    <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                      Links
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Sitemap
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Security
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Support Center
                    </div>
                    <div className="self-stretch relative tracking-[-0.2px]">
                      Cookie Preferences
                    </div>
                  </div>
                  <div className="w-[135.9px] flex flex-col items-start justify-start gap-[16px] text-4xs-4 text-neutral-1 font-open-sans">
                    <img
                      className="self-stretch h-10 relative max-w-full overflow-hidden shrink-0"
                      loading="lazy"
                      alt=""
                      src="/app-store--filled1.svg"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start">
                      <div className="flex-1 flex flex-row items-end justify-start pt-[4.7px] pb-[5.9px] pr-[9.2px] pl-2.5 relative gap-[19.8px]">
                        <img
                          className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                          alt=""
                          src="/vector.svg"
                        />
                        <img
                          className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full z-[1]"
                          alt=""
                          src="/vector-1.svg"
                        />
                        <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[3.3px]">
                          <img
                            className="w-[11.1px] h-[21.5px] relative z-[4]"
                            alt=""
                            src="/vector-5.svg"
                          />
                        </div>
                        <img
                          className="h-[13.4px] w-[16.2px] absolute !m-[0] bottom-[7.2px] left-[10.1px] z-[2]"
                          alt=""
                          src="/vector-2.svg"
                        />
                        <img
                          className="h-[11px] w-[12.5px] absolute !m-[0] top-[calc(50%_-_5.5px)] left-[20.5px] z-[3]"
                          alt=""
                          src="/vector-4.svg"
                        />
                        <div className="flex-1 flex flex-col items-start justify-start gap-[1.4px]">
                          <div className="relative inline-block [-webkit-text-stroke:0.2px_#fff] min-w-[40px] z-[2]">
                            GET IT ON
                          </div>
                          <img
                            className="self-stretch h-[17px] relative max-w-full overflow-hidden shrink-0 z-[2]"
                            loading="lazy"
                            alt=""
                            src="/vector-3.svg"
                          />
                        </div>
                        <img
                          className="h-[12.9px] w-[16.3px] absolute !m-[0] top-[7.2px] left-[10px] z-[5]"
                          alt=""
                          src="/vector-6.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch h-[58px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[0.5px] px-0 pb-[55.5px] box-border gap-[29.5px] text-mini mq750:h-auto">
                  <img
                    className="self-stretch relative max-w-full overflow-hidden max-h-full shrink-0 [debug_commit:1de1738]"
                    loading="lazy"
                    alt=""
                    src="/line-copy.svg"
                  />
                  <img
                    className="w-[15.1px] h-[13px] relative hidden"
                    alt=""
                    src="/heart2.svg"
                  />
                  <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px] mq750:flex-wrap">
                    <div className="w-[235.3px] relative tracking-[-0.1px] leading-[52px] inline-block shrink-0 [debug_commit:1de1738]">
                      © 2024 Copyright, All Right Reserved.
                    </div>
                    <div className="w-48 flex flex-col items-start justify-start pt-[2.1px] px-0 pb-0 box-border">
                      <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px]">
                        <img
                          className="h-6 w-6 relative min-h-[24px]"
                          alt=""
                          src="/buttontwitter.svg"
                        />
                        <img
                          className="h-6 w-6 relative min-h-[24px]"
                          alt=""
                          src="/buttonfacebook.svg"
                        />
                        <img
                          className="h-6 w-6 relative object-cover min-h-[24px]"
                          alt=""
                          src="/logoinstagram@2x.png"
                        />
                        <img
                          className="h-6 w-6 relative object-cover min-h-[24px]"
                          alt=""
                          src="/logolinkedin@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </section>
          </main>
        )
      )}
    </div>
  );
};

export default PractitionerDetails;
