import { useCallback } from "react";
import {
  TextField,
  Button,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SidebarLight from "../components/Layout/SidebarLight";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import ConsumerSchedule from "../components/Layout/ConsumerSchedule";
import { useDispatch, useSelector } from "react-redux";
import Inbox from "../components/Layout/Inbox";

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { updateProfileConsumer } from "../Actions/Consumer/ConsumerProfileUpdateAction";

import AccountDetailsForm from "../components/Layout/Profile";
import { setSidebar } from "../Actions/Consumer/SetSidebarTabAction";

const ConsumerProfile = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateDisable, setUpdateDisable] = useState(true);

  const [formValues, setFormValues] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: null,
    image: ""
  });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideBar = useSelector(state => state.sideBar.sideBar);
  // const onSidebarTileContainerClick = useCallback(() => {
  //   navigate("/consumer-schedule-upcoming");
  // }, [navigate]);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(UserData ? UserData : null);

  const [profileImage, setProfileImage] = useState(user ? user.user.profileImage : null);
  // const onSidebarTileContainerClick1 = useCallback(() => {
  //   navigate("/consumer-inbox");
  // }, [navigate]);
  const inbox = location.state?.inbox;
  const otherUserDetail = location.state?.otherUserDetail;
  const updateConsumerProfile = useSelector((state) => state.updateProfileConsumer.updateProfileConsumer);
  useEffect(() => {
    if (UserData && updateConsumerProfile && updateConsumerProfile !== "isLoading" && updateConsumerProfile.result.length !== 0) {
      const updatedUserData = updateConsumerProfile.result[0];
      if (UserData.user) {
        UserData.user.firstName = updatedUserData.firstName;
        UserData.user.lastName = updatedUserData.lastName;
        UserData.user.fullName = updatedUserData.fullName;
        UserData.user.dateOfBirth = updatedUserData.dateOfBirth;
        UserData.user.profileImage = updatedUserData.profileImage;

        localStorage.setItem('user', JSON.stringify(UserData));
        setUpdateDisable(true);
      } else {
        console.error('User data is missing in localStorage.');
      }

    } else {
      console.error('Update response is invalid or no result found.');
    }

  }, [updateConsumerProfile])

  useEffect(() => {
    if (user) {
      const userDateOfBirth = UserData.user.dateOfBirth;
      const dayjsDateOfBirth = userDateOfBirth ? dayjs(userDateOfBirth) : null;
      setFormValues({
        firstName: UserData.user.firstName || '',
        lastName: UserData.user.lastName || '',
        email: UserData.user.email || '',
        id: UserData.user.id || '',
        dateOfBirth: UserData.user.dateOfBirth ? UserData.user.dateOfBirth : null,
        image: UserData.user.ProfileImage
      });
      setDateOfBirth(dayjsDateOfBirth);
    }
  }, [user]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
    setUpdateDisable(false);
  };
  const handleDateChange = (newDate) => {
    setDateOfBirth(newDate);
    const formattedDate = newDate.toISOString();
    setFormValues(prevValues => ({
      ...prevValues,
      dateOfBirth: formattedDate,
    }));
    setUpdateDisable(false);
  };
  useEffect(() => {
    if (location.pathname === "/consumer-profile") {
      dispatch(setSidebar(0));
    } else if (location.pathname === "/consumer-schedule") {
      dispatch(setSidebar(1));
    } else if (location.pathname === "/consumer-inbox") {
      dispatch(setSidebar(2));
    }
  }, [location.pathname, dispatch]);

  const validateForm = (values) => {
    const errors = {};
    if (!values.firstName) errors.firstName = 'First name is required';
    if (!values.lastName) errors.lastName = 'Last name is required';
    if (!values.dateOfBirth) errors.dateOfBirth = 'Date of birth is required';
    return errors;
  };

  const handleSaveChanges = () => {
    const formErrors = validateForm(formValues);
    if (Object.keys(formErrors).length === 0) {
      const Id = formValues.id;
      const FirstName = formValues.firstName;
      const LastName = formValues.lastName;
      const DateOfBirth = formValues.dateOfBirth;
      const ProfileImage = formValues.image;
      dispatch(updateProfileConsumer(Id, FirstName, LastName, DateOfBirth, ProfileImage));
    } else {

      setErrors(formErrors);
    }
  }

  const handleImageUpdate = (newImage) => {
    setProfileImage(newImage);
    setFormValues(prevValues => ({
      ...prevValues,
      image: newImage,
    }));
    setUpdateDisable(false);
  };
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border gap-[20px] leading-[normal] tracking-[normal] mq750:gap-[10px]">
      <ConsumerHeader></ConsumerHeader>
      <main className="self-stretch flex flex-row items-start justify-center  box-border max-w-full">
        <section className="min-w-[1312px] flex flex-row items-start justify-center gap-[32px] max-w-full mq1050:flex-wrap mq750:gap-[16px]">
          <SidebarLight
            personOutline="/person_outline2.svg"
            eventNote="/event_note2.svg"
            forum="/forum.svg"
            inbox={inbox}
            image={profileImage}
            onImageChange={handleImageUpdate}

          />
          <div className="flex-1 w-[960px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[551px] max-w-full mq750:min-w-full">
            {sideBar === 1 &&
              <ConsumerSchedule></ConsumerSchedule>
            }
            {sideBar === 2 &&
              <Inbox otherUserDetail={otherUserDetail} />
            }
            {sideBar === 0 && (
              <AccountDetailsForm
                formValues={formValues}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                handleSaveChanges={handleSaveChanges}
                errors={errors}
                dateOfBirth={dateOfBirth}
                updateDisable={updateDisable}
                updateConsumerProfile={updateConsumerProfile}
              />
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ConsumerProfile;
