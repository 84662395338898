import axios from "axios";
import { BASE_URL } from "../Constants";
const API_URL = BASE_URL;
class SignInService
{
    async signIn(body)
    {
        return axios.post(BASE_URL + "Auth/LogIn", body);
    }
    async refreshToken(body)
    {
        return axios.post(BASE_URL + "Auth/RefreshAccessToken",body)
    }

}
export default new SignInService