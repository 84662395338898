import {GET_CATEGORIES_FAIL, GET_CATEGORIES_PENDING, GET_CATEGORIES_SUCCESS} from "../../Actions/Types";
  const initialState = {
    getAll: [],
  };
  const get_categories_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_CATEGORIES_SUCCESS:
        return {
          ...state,
          getAll: payload.getAll,
        };
      case GET_CATEGORIES_PENDING:
        return {
          ...state,
          getAll: payload.getAll,
        };
      case GET_CATEGORIES_FAIL:
        return {
          ...state,
          getAll: [],

        };
      default:
        return state;
    }
  };
  export default get_categories_reducer;

 