import {
    GET_NOTIFICATION_FAIL, GET_NOTIFICATION_PENDING, GET_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAIL, UPDATE_NOTIFICATION_PENDING, UPDATE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAIL, DELETE_NOTIFICATION_PENDING, DELETE_NOTIFICATION_SUCCESS,
    SET_MESSAGE
} from "./Types";
import UserService from '../services/user.service';


export const getNotifications = (userId) => async (dispatch) => {
    dispatch({
        type: GET_NOTIFICATION_PENDING,
        payload: { getNotifications: "isLoading" },
    });
    return await UserService.getNotifications(userId)
        .then(
            (data) => {
                console.log(data);
                if (data.data.status == true) {
                    dispatch({
                        type: GET_NOTIFICATION_SUCCESS,
                        payload: { getNotifications: data.data },
                    })
                }
                else {
                    dispatch({
                        type: GET_NOTIFICATION_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                console.log("error ", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch({
                    type: GET_NOTIFICATION_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                return Promise.reject();
            }
        )
        .catch((error) => {
            console.log(error);
        });
}

export const updateNotifications = (updateNotificationIds) => async (dispatch) => {
    dispatch({ type: UPDATE_NOTIFICATION_PENDING });

    return UserService.updateNotifications(updateNotificationIds)
        .then(data => {
            dispatch({ type: UPDATE_NOTIFICATION_SUCCESS, payload: data.data });
            return Promise.resolve();
        })
        .catch(error => {
            const message = error.response?.data?.message || error.message || error.toString();
            dispatch({ type: UPDATE_NOTIFICATION_FAIL });
            dispatch({ type: SET_MESSAGE, payload: message });
            return Promise.reject();
        });
};

export const deleteNotifications = (notificationIds) => async (dispatch) => {
    dispatch({ type: DELETE_NOTIFICATION_PENDING });

    return UserService.deleteNotifications(notificationIds)
        .then(data => {
            dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: data.data });
            return Promise.resolve();
        })
        .catch(error => {
            const message = error.response?.data?.message || error.message || error.toString();
            dispatch({ type: DELETE_NOTIFICATION_FAIL });
            dispatch({ type: SET_MESSAGE, payload: message });
            return Promise.reject();
        });
}