import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;
class ProfileDetailingService
{
    async addPractitionerTag(body)
    {
        interceptor();
        return axios.post(API_URL + "Practitioner/AddPractitionerTag",body,{ headers: authHeader() });
    }
    async addCategory(categoryId, userId)
    {
        interceptor();
       
        return axios.post(
            `${API_URL}Practitioner/AddPractitionerCategory`,
            {},
            {
                params: {
                    categoryId,
                    userId
                },
                headers: authHeader()
            }
        );
    }
    async addBio(body)
    {
        interceptor();
        return axios.post(API_URL + "Practitioner/AddPractitionerBio",body,{ headers: authHeader() });
    }
    async addPractitionerBussiness(body)
    {
        interceptor();
        return axios.post(API_URL + "Practitioner/AddPractitionerBussinessDetail",body,{ headers: authHeader() });
    }
    async uploadUserImages(body)
    {
        interceptor();
        return axios.post(API_URL + "Practitioner/UploadUserImages", body, {
            headers: {
               ...authHeader(),
              'Content-Type': 'multipart/form-data'
            }
          });
    }

}
export default new ProfileDetailingService