import React from 'react';

const MessageContact = ({ contact, isSelected, onClick, messages }) => {
    // const lastMessage = messages.find(msg => msg.uid === contact.id);
    const lastMessage = messages[0];
    const getInitials = (name) => {
        const names = name.split(' ');
        const initials = names.map(n => n[0]).join('');
        return initials;
    };
    const timeAgo = (timestamp) => {
        const now = new Date();
        const timeDifference = Math.floor((now - timestamp) / 1000);

        if (timeDifference < 60) {
            return 'now';
        } else if (timeDifference < 3600) {
            return `${Math.floor(timeDifference / 60)}m`;
        } else if (timeDifference < 86400) {
            return `${Math.floor(timeDifference / 3600)}h`;
        } else {
            return `${Math.floor(timeDifference / 86400)}d`;
        }
    };
    return (
        <div
            onClick={onClick}
            className={`self-stretch overflow-hidden flex flex-row w-[317px] h-[72px] items-start justify-start p-[12px] gap-[16px] rounded-3xs ${isSelected ? 'bg-[#E9E8E8]' : 'bg-neutral'} hover:bg-[#F5F5F5] cursor-pointer`}
        >
            {contact.img ? (
                <img
                    className="h-[48px] w-[48px] relative rounded-3xs object-cover"
                    loading="lazy"
                    alt={contact.name}
                    src={contact.img}
                />
            ) : (
                <div className="h-[48px] w-[48px] relative rounded-3xs bg-gray-400 flex items-center justify-center text-white text-lg font-bold">
                    {getInitials(contact.name)}
                </div>
            )}
            <div className="self-stretch flex flex-col items-start justify-start w-[229px] h-[44px] gap-[0px]">
                <div className="self-stretch flex flex-row items-start justify-between gap-[12px] w-full h-[22px]">
                    <div className="flex-1 relative leading-[22px] text-sm font-semibold">
                        {contact.name}
                    </div>
                    <div className="relative leading-[22px] font-semibold text-gray-900 text-[12px] inline-block min-w-[26px]">
                        {lastMessage?.timestamp ? timeAgo(new Date(lastMessage.timestamp)) : ''}
                    </div>
                </div>
                <div
                    className="w-[200px] h-[20px] overflow-hidden text-ellipsis whitespace-nowrap text-sm text-gray-300"
                >
                    {lastMessage ? lastMessage.message : ""}
                </div>
            </div>
        </div>
    );
};

export default MessageContact;