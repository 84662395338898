
import {GET_PRACTITIONER_FAIL, GET_PRACTITIONER_PENDING, GET_PRACTITIONER_SUCCESS} from "../../Actions/Types";
    const initialState = {
        getPractitioners: [],
    };
    const get_featured_practitioners = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_PRACTITIONER_SUCCESS:
          return {
            ...state,
            getPractitioners: payload.getPractitioners,
          };
        case GET_PRACTITIONER_PENDING:
          return {
            ...state,
            getPractitioners: payload.getPractitioners,
          };
      case GET_PRACTITIONER_FAIL:
        return {
          ...state,
          getPractitioners: [],
        };
        default:
          return state;
      }
    };
    export default get_featured_practitioners;