import {ADD_BIO_FAIL, ADD_BIO_PENDING, ADD_BIO_SUCCESS, RESET_ADD_BIO, } from "../../Actions/Types";

const initialState = {
    addBio: []
}

const bio_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case ADD_BIO_SUCCESS:
        return {
          ...state,
          addBio: payload.addBio,
        };
      case ADD_BIO_PENDING:
        return {
          ...state,
          addBio: payload.addBio,
        };
      case ADD_BIO_FAIL:
        return {
          ...state,
          addBio: [],

        };
        case RESET_ADD_BIO:
          return {
            ...state,
            addBio: [],
  
          };
      default:
        return state;
    }
  };
  export default bio_reducer ;