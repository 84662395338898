import axios from "axios";
import { BASE_URL } from "../Constants";

class GoogleLoginService {
  async googleLogin(body) {
    return axios.post(`${BASE_URL}Auth/GoogleSignUp`, body);
  }

  async refreshToken(body) {
    return axios.post(`${BASE_URL}Auth/RefreshAccessToken`, body);
  }
}

export default new GoogleLoginService();
