import { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import { signIn, resetSigninState } from "../../Actions/Auth/SigninAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/SubComponent/Loader";
import { useNavigate } from "react-router-dom";
import { confirmUser } from "../../Actions/Auth/ConfirmUserAction";
import { getPractitioner } from "../../Actions/Consumer/GetPractitionerAction";
const InputFields = () => {
  useEffect(() => {
    dispatch(resetSigninState());
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signInState = useSelector((state) => state.signin);
  const [showPassword, setShowPassword] = useState(false);
 
  const [checkBoxFilledIconChecked, setCheckBoxFilledIconChecked] =
    useState(true);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const practitioner = useSelector((state) => state.practitioner.practitioner);


  useEffect(() => {
    dispatch(resetSigninState());
    if (user?.user?.id) {
      dispatch(getPractitioner(user?.user?.id));
    }
  }, [dispatch]);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userType: 1,
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid Email Format";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
 

  
  const handleSignIn = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(signIn(formData));
     
    }
   
  };
  const handleSignUpClick = () => {
    navigate("/practitioner-sign-up");
  };
  const handleHomeClick = () => {
    navigate("/practitioner-landing-page");
  };
  const handleForgot = () => {
    navigate("/practitioner-forgot-password");
  };

  const navigateToNext = () => {
    if (practitioner && practitioner.result) {
      const categories = practitioner.result[0].categories;

      if ( !categories || categories.length === 0) {
        navigate("/practitioner-profile-detailing"); 
       
      } else {
        navigate("/dashboard"); 
      }
    } else {
      console.log("Practitioner not found.");
    }
  };
  const handleConfirm = () => {
    const body = formData;
    dispatch(confirmUser(body));
    navigate("/practitioner-sign-up-email-verification");
  };
  console.log(signInState);
  return (
    <form className="m-0 self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full mq750:gap-[16px]">
      <div className="self-stretch relative text-base leading-[24px] font-web-secondary-underline-subtitle2-dm-sans text-gray-200 text-left">
        Enter your credentials to access your account
      </div>
      <div className="self-stretch flex flex-row items-start justify-start max-w-full">
        <TextField
          className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
          color="primary"
          label="Email"
          placeholder="user@email.com"
          required={true}
          fullWidth={true}
          variant="standard"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          error={!!errors.email}
          helperText={errors.email}
          sx={{ "& .MuiInputBase-root": { height: "53px" } }}
        />
        <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
          <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
            Helper text
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start max-w-full">
        <TextField
          className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full mq450:h-auto"
          color="primary"
          label="Password"
          required={true}
          fullWidth={true}
          variant="standard"
          type={showPassword ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          error={!!errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleShowPasswordClick}
                  aria-label="toggle password visibility"
                >
                  <Icon>{showPassword ? "visibility_off" : "visibility"}</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ "& .MuiInputBase-root": { height: "53px" } }}
        />
        <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-10 pb-0 box-border">
          <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
            Helper text
          </div>
        </div>
      </div>
      <Button
        className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
        variant="contained"
        sx={{
          textTransform: "none",
          color: "#fff",
          fontSize: "18",
          background: "#006a6a",
          border: "#006a6a solid 1px",
          borderRadius: "4px",
          "&:hover": { background: "#006a6a" },
        }}
        onClick={handleSignIn}
      >
        {signInState.signIn === "isLoading" ? (
          <div className="mt-2">
            <Loader />
          </div>
        ) : signInState.signIn.status && signInState.signIn.status == true ? (
          navigateToNext() 
        ): signInState.signIn.errorCode && signInState.signIn.errorCode == "UserNotConfirmed" ? (
          handleConfirm() 
        ) :
          (
          "Sign In"
        )}
      </Button>
      <div className="self-stretch rounded overflow-hidden flex flex-row items-start justify-center py-1 px-5 gap-[10px]">
        <div className="bg-hitbox-1000 overflow-hidden hidden flex-col items-center justify-center">
          <img
            className="w-3.5 h-3.5 relative overflow-hidden shrink-0 object-cover"
            alt=""
            src="/wrapper-12@2x.png"
          />
        </div>
        <Button
          className="relative min-w-[119px]"
          disableElevation={true}
          variant="text"
          sx={{ borderRadius: "0px 0px 0px 0px" }}
          onClick={handleForgot}
        >
          Forgot Password?
        </Button>
      </div>
      <div className="flex flex-row items-start justify-start gap-[2px]">
        <div className="relative text-sm leading-[22px] font-web-primary-h3-primary-dm-sans text-gray-200 text-left mt-1">
          Don’t have an account?
        </div>
        <Button
          variant="text"
          className="text-sm leading-[22px] pt-[3px] font-semibold font-web-primary-h3-primary-dm-sans text-royalblue whitespace-nowrap"
          onClick={handleSignUpClick}
          style={{ textTransform: 'lowercase' }}
        >
          Sign Up
        </Button>
      </div>
      <Button
        className="w-[154px] h-[42px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
        startIcon={
          <img width="16px" height="16px" src="/navigate-before.svg" />
        }
        variant="contained"
        sx={{
          textTransform: "none",
          color: "#434343",
          fontSize: "14",
          background: "#fff",
          border: "#d9d9d9 solid 1px",
          borderRadius: "4px",
          "&:hover": { background: "#fff" },
          width: 154,
          height: 42,
        }}
        onClick={handleHomeClick}
      >
        Back to home
      </Button>
    </form>
  );
};

export default InputFields;
