
import {ADD_SCHEDULE_FAIL, ADD_SCHEDULE_PENDING, ADD_SCHEDULE_SUCCESS, RESET_ADD_SCHEDULE} from "../../Actions/Types";
    const initialState = {
        addSchedule: "",
    };
    const addScheduleReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case ADD_SCHEDULE_SUCCESS:
          return {
            ...state,
            addSchedule: payload.addSchedule,
          };
        case ADD_SCHEDULE_PENDING:
          return {
            ...state,
            addSchedule: payload.addSchedule,
          };
        case ADD_SCHEDULE_FAIL:
        return {
          ...state,
        };
        case RESET_ADD_SCHEDULE:
      return {
        ...state,
        addSchedule: "", 
      };
        default:
          return state;
      }
    };
    export default addScheduleReducer;