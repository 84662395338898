import { LOGOUT_FAIL, LOGOUT_PENDING, LOGOUT_SUCCESS, RESET_LOGOUT_STATE } from "../../Actions/Types";
const initialState = {
  logout: []
};
const logout_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: payload.logout,
      };
    case LOGOUT_PENDING:
      return {
        ...state,
        logout: payload.logout,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        logout: [],
      };
    case RESET_LOGOUT_STATE:
      return {
        ...state,
        logout: initialState.logout,
      };
    default:
      return state;
  }
};
export default logout_reducer;