import { SET_SIDEBAR_TAB, RESET_SIDEBAR_TAB } from "../Types";


export const setSidebar = (num) => ({
    type: SET_SIDEBAR_TAB,
    payload: { sideBar: num },
});


export const resetSidebar = () => ({
    type: RESET_SIDEBAR_TAB,
    payload: { sideBar: 0 },
});