import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';


const CustomDatePicker = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const formattedDate = value ? value.format('MMMM D, YYYY') : '';
  const defaultValue = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ position: 'relative', width: '100%' }}>
        <TextField
          id="date"
          label="Date"
          variant="standard"
          className="w-full h-full"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <TodayOutlinedIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpen(!open)}
                />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={formattedDate}
          sx={{
            '.MuiInputBase-root': {
              height: '100%',
            },
            '.MuiInputLabel-root': {
              transform: 'translate(0, 1.5px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(0, -1.5px) scale(0.75)',
              },
            },
            '.MuiFormControl-root': {
              height: '100%',
            },
          }}
        />
        {open && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 10,
              width: '100%',
            }}
          >
            <DateCalendar
              value={value}
              onChange={(newValue) => {
                onChange(newValue);
                setOpen(false);
              }}
              minDate={dayjs().startOf('day')}
              sx={{
                width: '45%',
                backgroundColor: 'white',
                borderRadius: '4px',
                border: "1px solid grey"
              }}
            />
          </div>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;