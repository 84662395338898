
import {UPDATE_SCHEDULE_FAIL, UPDATE_SCHEDULE_PENDING, UPDATE_SCHEDULE_SUCCESS, RESET_UPDATE_SCHEDULE} from "../../Actions/Types";
    const initialState = {
        updateSchedule: "",
    };
    const updateScheduleReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case UPDATE_SCHEDULE_SUCCESS:
          return {
            ...state,
            updateSchedule: payload.updateSchedule,
          };
        case UPDATE_SCHEDULE_PENDING:
          return {
            ...state,
            updateSchedule: payload.updateSchedule,
          };
        case UPDATE_SCHEDULE_FAIL:
        return {
          ...state,
        };
        case RESET_UPDATE_SCHEDULE:
      return {
        ...state,
        updateSchedule: "", 
      };
        default:
          return state;
      }
    };
    export default updateScheduleReducer;