import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, IconButton, Chip
} from '@mui/material';
import { useDispatch } from "react-redux";
import { setSelection, setServiceData } from '../../Actions/Practitioner/addServiceAction';

const ServiceTable = ({ services }) => {
  const dispatch = useDispatch();

  const handleEdit = (service) => {
    let num = 7;
    dispatch(setServiceData(service));
    dispatch(setSelection(num));
  };

  return (
    <TableContainer
      component={Paper}
      className="border border-gray-300 rounded-md overflow-hidden"
    >
      <Table className="w-full">
        <TableHead>
          <TableRow className="bg-gray-100">
            <TableCell>
              <div className="flex items-center min-w-[120px]">
                <img
                  src="/serviceName.svg"
                  alt="Service Name Icon"
                  className="mr-2"
                />
                Name
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center min-w-[120px]">
                <img src="/category.svg" alt="Category Icon" className="mr-2" />
                Category
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center min-w-[100px]">
                <img src="/attach_money.svg" alt="Cost Icon" className="mr-2" />
                Cost
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center min-w-[120px]">
                <img
                  src="/sessionType.svg"
                  alt="Session Icon"
                  className="mr-2"
                />
                Session
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center min-w-[120px]">
                <img src="/data_usage.svg" alt="Status Icon" className="mr-2" />
                Status
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center min-w-[120px]">
                <img src="/touch_app.svg" alt="Action Icon" className="mr-2" />
                Action
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.id} className="border-t border-gray-300">
              <TableCell className="py-2">{service.name}</TableCell>
              <TableCell className="py-2">{service.categoryName}</TableCell>
              <TableCell className="py-2 pl-8">${service.cost}</TableCell>
              <TableCell className="py-2">
                {service.type === 0
                  ? "Online"
                  : service.type === 1
                  ? "On-site"
                  : "Online and Onsite"}
              </TableCell>
              <TableCell className="py-2">
                <Chip className='font-medium rounded-lg'
                  label={service.isActive ? "Active" : "Inactive"}
                  style={{
                    color: service.isActive ? "#434343" : "#434343",
                    backgroundColor: service.isActive ? "#B7EB8F" : "#FFA39E",
                    width: '100px', 
                    
                  }}
                  variant="outlined"
                />
              </TableCell>
              <TableCell className="py-2">
                <IconButton
                  aria-label="edit"
                  onClick={() => handleEdit(service)}
                >
                  <img src="/actionButton.svg" alt="Edit Icon" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiceTable;