import {FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_PENDING, FORGOT_PASSWORD_SUCCESS, RESET_FORGOT_PASSWORD_STATE} from "../../Actions/Types";
  const initialState = {
    forgotPassword: [],
    UserData: null,
  };
  const forgot_password_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          forgotPassword: payload.forgotPassword,
          UserData: payload.UserData,
        };
      case FORGOT_PASSWORD_PENDING:
        return {
          ...state,
          forgotPassword: payload.forgotPassword,
          UserData: null,
        };
      case FORGOT_PASSWORD_FAIL:
        return {
          ...state,
          forgotPassword: [],
          UserData: null,

        };
        case RESET_FORGOT_PASSWORD_STATE:
      return {
        ...state,
        forgotPassword: initialState.forgotPassword, 
        UserData: initialState.UserData, 
      };
      default:
        return state;
    }
  };
  export default forgot_password_reducer;