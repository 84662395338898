
import {GET_BOOKING_FAIL, GET_BOOKING_PENDING, GET_BOOKING_SUCCESS, GET_HISTORY_BOOKING_FAIL, GET_HISTORY_BOOKING_PENDING, GET_HISTORY_BOOKING_SUCCESS, GET_UPCOMING_BOOKING_FAIL, GET_UPCOMING_BOOKING_PENDING, GET_UPCOMING_BOOKING_SUCCESS} from "../../Actions/Types"
    const initialState = {
        getBooking: [],
        getUpcomingBooking: [],
        getHistoryBooking: [],
    };
    const getBookingReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_BOOKING_SUCCESS:
          return {
            ...state,
            getBooking: payload.getBooking,
          };
        case GET_BOOKING_PENDING:
          return {
            ...state,
            getBooking: payload.getBooking,
          };
        case GET_BOOKING_FAIL:
        return {
          ...state,
        };
        case GET_HISTORY_BOOKING_SUCCESS:
          return {
            ...state,
            getHistoryBooking: payload.getHistoryBooking,
          };
        case GET_HISTORY_BOOKING_PENDING:
          return {
            ...state,
            getHistoryBooking: payload.getHistoryBooking,
          };
        case GET_HISTORY_BOOKING_FAIL:
        return {
          ...state,
        };

        case GET_UPCOMING_BOOKING_SUCCESS:
          return {
            ...state,
            getUpcomingBooking: payload.getUpcomingBooking,
          };
        case GET_UPCOMING_BOOKING_PENDING:
          return {
            ...state,
            getUpcomingBooking: payload.getUpcomingBooking,
          };
        case GET_UPCOMING_BOOKING_FAIL:
        return {
          ...state,
        };
        
        default:
          return state;
      }
    };
    export default getBookingReducer;