import {PREFERENCE_SUCCESS, PREFERENCE_PENDING, CREATE_PREFERENCE_FAIL, CREATE_PREFERENCE_PENDING, CREATE_PREFERENCE_SUCCESS, SET_MESSAGE} from "../Types"
import ConsumerService from '../../services/ConumerService';



export const changeSelection = (selected) => async (dispatch) => {
          {
            dispatch({
                type: PREFERENCE_SUCCESS,
                payload: { selection: selected },
              })
            }
  };

  export const createPreferences = (body) => async (dispatch) => {
    dispatch({
      type: CREATE_PREFERENCE_PENDING,
      payload: { create: "isLoading" },
    });
    return await ConsumerService.createConsumerPreference(body)
      .then(
        (data) => {
          console.log(data);
          if(data.data.status == true)
          {
            dispatch({
                type: CREATE_PREFERENCE_SUCCESS,
                payload: { create: data.data,  },
              })
            }
            else
            { dispatch({
                type: CREATE_PREFERENCE_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: CREATE_PREFERENCE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });

  };