import {
    GET_NOTIFICATION_FAIL, GET_NOTIFICATION_PENDING, GET_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAIL, UPDATE_NOTIFICATION_PENDING, UPDATE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAIL, DELETE_NOTIFICATION_PENDING, DELETE_NOTIFICATION_SUCCESS
} from "../Actions/Types";

const initialState = {
    getNotifications: [],
    deleteNotifications: "",
    updateNotifications: ""
};

export const getNotificationsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                getNotifications: payload.getNotifications,
            };
        case GET_NOTIFICATION_PENDING:
            return {
                ...state,
                getNotifications: payload.getNotifications,
            };
        case GET_NOTIFICATION_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export const deleteNotificationsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                deleteNotifications: payload.deleteNotifications,
            };
        case DELETE_NOTIFICATION_PENDING:
            return {
                ...state,
            };
        case DELETE_NOTIFICATION_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export const updateNotificationsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_NOTIFICATION_PENDING:
            return { ...state, isLoading: true };
        case UPDATE_NOTIFICATION_SUCCESS:
            return { ...state, updateNotifications: payload.updateNotifications };
        case UPDATE_NOTIFICATION_FAIL:
            return { ...state, error: payload };
        default:
            return state;
    }
};