import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';

const API_URL = BASE_URL;
class ScheduleService
{
   
    async getSchedules(id) {
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        interceptor();
        return axios.get(API_URL + `PractitionerSchedule/GetSchedules`, {
            headers: {
                ...authHeader(),
                TimeZoneOffSet: timeZoneOffset,
            },
            params: {
                'Id': id
            }
        });
    }

}
export default new ScheduleService