import {CANCEL_BOOKING_FAIL, CANCEL_BOOKING_PENDING, CANCEL_BOOKING_SUCCESS, RESET_CANCEL_BOOKING} from "../../Actions/Types"
    const initialState = {
        cancelBooking: ""
    };
    const cancelBookingReducerConsumer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case CANCEL_BOOKING_SUCCESS:
          return {
            ...state,
            cancelBooking: payload.cancelBooking,
          };
        case CANCEL_BOOKING_PENDING:
          return {
            ...state,
            cancelBooking: payload.cancelBooking,
          };
        case CANCEL_BOOKING_FAIL:
        return {
          ...state,
        };
        case RESET_CANCEL_BOOKING:
        return {
          ...state,
          cancelBooking: "",
        };


        default:
          return state;
      }
    };
    export default cancelBookingReducerConsumer;