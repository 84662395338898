
import {GET_PRACTITIONER_ID_FAIL, GET_PRACTITIONER_ID_PENDING, GET_PRACTITIONER_ID_SUCCESS} from "../../Actions/Types";
    const initialState = {
        practitioner: [],
    };
    const get_practitioner = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_PRACTITIONER_ID_SUCCESS:
          return {
            ...state,
            practitioner: payload.practitioner,
          };
        case GET_PRACTITIONER_ID_PENDING:
          return {
            ...state,
            practitioner: payload.practitioner,
          };
      case GET_PRACTITIONER_ID_FAIL:
        return {
          ...state,
          practitioner: [],
        };
        default:
          return state;
      }
    };
    export default get_practitioner;