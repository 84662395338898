import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;
class AddService
{
    async addService(body)
    {
        interceptor();
        return axios.post(API_URL + "OfferedServices/AddService",body,{ headers: authHeader() });
    }

    async updateService(body)
    {
        interceptor();
        return axios.put(API_URL + "OfferedServices/UpdateService",body,{ headers: authHeader() });
    }

    
}
export default new AddService