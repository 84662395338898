import { GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_PENDING, GOOGLE_LOGIN_SUCCESS, RESET_GOOGLE_LOGIN_STATE } from "../../Actions/Types";

const initialState = {
  googleLogin:[],
  UserData: null,
};

const googleLogin_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        googleLogin: payload.googleLogin,
        UserData: payload.UserData,
      };
    case GOOGLE_LOGIN_PENDING:
      return {
        ...state,
        googleLogin: payload.googleLogin,
        UserData: null,
      };
    case GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        googleLogin: initialState.googleLogin,
        UserData: initialState.UserData,
      };
    case RESET_GOOGLE_LOGIN_STATE:
      return {
        ...state,
        googleLogin: initialState.googleLogin,
        UserData: initialState.UserData,
      };
    default:
      return state;
  }
};

export default googleLogin_reducer;
