import { useState , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSelection } from "../../Actions/Consumer/ConsumerPreferenceAction";

const ConsumerPreferencesSide = () => {
  const dispatch = useDispatch();
  const selection = useSelector(state => state.selected.selected);
  let selected = '';
  const handleGenderClick = () => {
    selected = 'gender';
    dispatch(changeSelection(selected));
  };
  const handleDateClick = () => {
    selected = 'date';
    dispatch(changeSelection(selected));
  };
  const handlePhysicalClick = () => {
    selected = 'physical';
    dispatch(changeSelection(selected));
  };
  const handleWellnessClick = () => {
    selected = 'wellness';
    dispatch(changeSelection(selected));
  };

  return (
    <div className="w-[180px] flex flex-col items-start justify-start gap-[24px] min-w-[180px] mq750:flex-1">
      {selection === "gender" ? (
        <div className="self-stretch rounded-2xl bg-royalblue flex flex-col items-start justify-start pt-2.5 pb-[34px] pr-2.5 pl-[57px] gap-[16px] text-neutral-1">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[3px]">
            <div className="flex-1 flex flex-col items-end justify-start gap-[10px]">
              <div className="w-3.5 h-3.5 relative rounded-[50%] bg-neutral-1" />
              <div className="self-stretch flex flex-row items-start justify-start">
                <img
                  className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/preferences-icons.svg"
                />
              </div>
            </div>
          </div>
          <b className="relative leading-[28px] inline-block min-w-[66px]">
            Gender
          </b>
        </div>
      ) : (
        <div
          className="shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] rounded-2xl bg-m3-sys-light-on-primary flex flex-col items-start justify-start py-[34px] px-[57px] gap-[16px] cursor-pointer"
          onClick={handleGenderClick}
        >
          <div className="flex flex-row items-start justify-start py-0 px-[3px]">
            <img
              className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/preferences-icons1.svg"
            />
          </div>
          <b className="relative leading-[28px] inline-block min-w-[66px]">
            Gender
          </b>
        </div>
      )}
      {selection === "date" ? (
        <div className="self-stretch rounded-2xl bg-royalblue flex flex-col items-center justify-center pt-2.5 pb-[34px] pr-2.5 pl-[57px] gap-[16px] text-neutral-1">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[3px]">
            <div className="flex-1 flex flex-col items-end justify-start gap-[10px]">
              <div className="w-3.5 h-3.5 relative rounded-[50%] bg-neutral-1" />
              <div className="self-stretch flex flex-row items-start justify-start">
                <img
                  className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/preferences-icons-11.svg"
                />
              </div>
            </div>
          </div>
          <b className="relative leading-[28px] inline-block min-w-[111px] mr-12">
            Date of Birth
          </b>
        </div>
      ) : (
        <div
          className="shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] rounded-2xl bg-neutral-1 flex flex-col items-start justify-start py-[34px] px-[34.5px] gap-[16px] cursor-pointer"
          onClick={handleDateClick}
        >
          <div className="flex flex-row items-start justify-start py-0 pr-[25px] pl-[25.5px]">
            <img
              className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
              alt=""
              src="/preferences-icons-1.svg"
            />
          </div>
          <b className="relative leading-[28px] inline-block min-w-[111px]">
            Date of Birth
          </b>
        </div>
      )}
      {selection === "physical" ? (
        <div className="self-stretch rounded-2xl bg-royalblue flex flex-col items-center justify-center pt-2.5 pb-[34px] pr-2.5 pl-[57px] gap-[16px] text-neutral-1">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[3px]">
            <div className="flex-1 flex flex-col items-end justify-start gap-[10px]">
              <div className="w-3.5 h-3.5 relative rounded-[50%] bg-neutral-1" />
              <div className="self-stretch flex flex-row items-start justify-start">
                <img
                  className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/preferences-icons-21.svg"
                />
              </div>
            </div>
          </div>
          <b className="relative leading-[28px] inline-block min-w-[150px] mr-12">
            Physical Profile
          </b>
        </div>
      ) : (
        <div
          className="shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] rounded-2xl bg-neutral-1 flex flex-col items-start justify-start py-[34px] px-[23px] gap-[16px] cursor-pointer"
          onClick={handlePhysicalClick}
        >
          <div className="flex flex-row items-start justify-start py-0 px-[37px]">
            <img
              className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
              alt=""
              src="/preferences-icons-2.svg"
            />
          </div>
          <b className="relative leading-[28px]">Physical Profile</b>
        </div>
      )}
      {selection === "wellness" ? (
        <div className="self-stretch rounded-2xl bg-royalblue flex flex-col items-center justify-center pt-2.5 pb-[34px] pr-2.5 pl-[57px] gap-[16px] text-neutral-1">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[3px]">
            <div className="flex-1 flex flex-col items-end justify-start gap-[10px]">
              <div className="w-3.5 h-3.5 relative rounded-[50%] bg-neutral-1" />
              <div className="self-stretch flex flex-row items-start justify-start">
                <img
                  className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/preferences-icons-31.svg"
                />
              </div>
            </div>
          </div>
          <b className="relative leading-[28px] inline-block min-w-[150px] mr-12">
            Wellness Goals
          </b>
        </div>
      ) : (
        <div
          className="self-stretch shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] rounded-2xl bg-neutral-1 flex flex-col items-start justify-start py-[34px] px-[23px] gap-[16px] cursor-pointer"
          onClick={handleWellnessClick}
        >
          <div className="flex flex-row items-start justify-start py-0 px-[37px]">
            <img
              className="h-[60px] w-[60px] relative overflow-hidden shrink-0"
              alt=""
              src="/preferences-icons-3.svg"
            />
          </div>
          <b className="self-stretch relative leading-[28px]">Wellness Goals</b>
        </div>
      )}
    </div>
  );
};

export default ConsumerPreferencesSide;
