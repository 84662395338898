import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getElasticServices } from "../../Actions/Consumer/ElasticServicesAction";

const SectionExploreCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (text) => {
    const searchVal = text;
    const searchText = text;
    if (searchVal && searchVal !== null && searchVal.length > 0) {
      dispatch(getElasticServices(searchVal));
      navigate("/search-results-services", { state: { searchText } });
    }
  };
  return (
    <section className="self-stretch flex flex-col items-start justify-start pb-14 box-border gap-[60px] max-w-[1440px] text-left text-13xl text-character-title-85 font-web-primary-h3-primary-dm-sans mq750:py-9 mq750:px-[50px] mq750:box-border mq450:pl-5 mq450:pr-5 mq450:box-border">
      <h2 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq750:text-7xl mq750:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
        Explore Categories
      </h2>
      <div className="self-stretch overflow-x-auto flex flex-row flex-wrap items-start justify-start gap-[32px] min-h-[332px] max-w-[1240px] text-center text-neutral-1">
        <div className="w-[392px] rounded-3xs shrink-0 flex flex-row items-center justify-center py-[27px] px-[7px] box-border bg-[url('/public/divcategory@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full cursor-pointer"
          onClick={() => handleClick("Psychologists and Counselors")}
        >
          <h2 className="m-0 flex-1 relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-full mq750:text-7xl mq750:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Psychologists and Counselors
          </h2>
        </div>
        <div className="w-[392px] rounded-3xs shrink-0 flex flex-row items-center justify-center py-[51px] px-[7px] box-border bg-[url('/public/divcategory1@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full cursor-pointer"
          onClick={() => handleClick("Mind")}
        >
          <h2 className="m-0 flex-1 relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-full mq750:text-7xl mq750:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Mind
          </h2>
        </div>
        <div className="w-[392px] rounded-3xs shrink-0 flex flex-row items-center justify-center py-[51px] px-[7px] box-border bg-[url('/public/divcategory2@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full cursor-pointer"
          onClick={() => handleClick("Body")}
        >
          <h2 className="m-0 flex-1 relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-full mq750:text-7xl mq750:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Body
          </h2>
        </div>
        <div className="w-[392px] rounded-3xs shrink-0 flex flex-row items-center justify-center py-[51px] px-[7px] box-border bg-[url('/public/divcategory3@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full cursor-pointer"
          onClick={() => handleClick("Medicine")}
        >
          <h2 className="m-0 flex-1 relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-full mq750:text-7xl mq750:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Medicine
          </h2>
        </div>
      </div>
    </section>
  );
};

export default SectionExploreCategories;
