const SectionTheHealersApp = () => {
  return (
    <section className="self-stretch bg-consumer-colors-primary-1 flex flex-row items-center justify-center py-[50px] px-[100px] box-border gap-[56px] max-w-full text-left text-smi text-consumer-colors-monochromatic font-web-primary-h3-primary-dm-sans mq1225:flex-wrap mq1225:pl-[50px] mq1225:pr-[50px] mq1225:box-border mq750:gap-[28px] mq750:py-[91px] mq750:px-[25px] mq750:box-border">
    <div className="flex flex-col items-start justify-start gap-[32px] min-w-[690px] max-w-[690px] mq1225:flex-1 mq1050:min-w-full mq750:gap-[16px]">
      <div className="w-[589px] flex flex-col items-start justify-start gap-[8px] max-w-full">
        <b className="self-stretch relative tracking-[1.63px] uppercase">
         Healers Inc app
        </b>
        <h1 className="m-0 w-[568px] relative text-37xl tracking-[-1.8px] leading-[110%] font-bold font-inherit text-gray-200 inline-block max-w-full mq450:text-15xl mq450:leading-[37px] mq750:text-26xl mq750:leading-[49px]">
          Experience Wellness On the Go
        </h1>
      </div>
      <div className="self-stretch relative text-xl tracking-[-0.2px] leading-[180%] text-gray-700 mix-blend-normal mq450:text-base mq450:leading-[29px]">
        Take your wellness journey with you wherever you go with our mobile
        app. Seamlessly access our services, schedule appointments, and stay
        connected with your practitioner—all from the palm of your hand.
        With user-friendly navigation and convenient features, our app makes
        it easy to prioritize your well-being, no matter how busy life gets.
      </div>
    </div>
    <div className="w-[494px]">
      <img
        className="w-[494px] h-auto"
        loading="lazy"
        alt=""
        src="/Image.png"
      />
    </div>
  </section>
  );
};

export default SectionTheHealersApp;
