
import {PREFERENCE_PENDING, PREFERENCE_SUCCESS, CREATE_PREFERENCE_PENDING,
  CREATE_PREFERENCE_SUCCESS,
  CREATE_PREFERENCE_FAIL} from "../../Actions/Types";
  const initialState = {
    selected: 'gender',
    create: null,
  };
  const change_selection_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case PREFERENCE_SUCCESS:
        return {
          ...state,
          selected: payload.selection,
        };
      case PREFERENCE_PENDING:
        return {
          ...state,
          selected: payload.selection,
        };
        case CREATE_PREFERENCE_PENDING:
      return {
        ...state,
        create: payload.create,
      };
    case CREATE_PREFERENCE_SUCCESS:
      return {
        ...state,
        create: payload.create,
      };
    case CREATE_PREFERENCE_FAIL:
      return {
        ...state,
        create: null,
      };
      default:
        return state;
    }
  };
  export default change_selection_reducer;