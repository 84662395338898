import axios from "axios";
import { BASE_URL } from "../Constants";
const API_URL = BASE_URL;
class CategoriesService
{
    async getAll()
    {
        return axios.get(BASE_URL + "Category/GetAll");
    }

}
export default new CategoriesService