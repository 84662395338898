import {CONFIRM_FORGOT_PASSWORD_FAIL, CONFIRM_FORGOT_PASSWORD_PENDING, CONFIRM_FORGOT_PASSWORD_SUCCESS} from "../../Actions/Types";
  const initialState = {
    confirmForgotPassword: [],
  };
  const confirm_forgot_password_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CONFIRM_FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          confirmForgotPassword: payload.confirmForgotPassword,
        };
      case CONFIRM_FORGOT_PASSWORD_PENDING:
        return {
          ...state,
          confirmForgotPassword: payload.confirmForgotPassword,
        };
      case CONFIRM_FORGOT_PASSWORD_FAIL:
        return {
          ...state,
          confirmForgotPassword: [],

        };
      default:
        return state;
    }
  };
  export default confirm_forgot_password_reducer;