
import {
   
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_FAIL,
    RESET_DELETE_IMAGE,
    DELETE_IMAGE_PENDING
} from "../../Actions/Types";

const initialState = {
    deleteImageStatus: 'idle', 
    deletedImageUrl: null,
    error: null,
};

const deleteImageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DELETE_IMAGE_PENDING:
            return {
                ...state,
                deleteImageStatus: 'loading',
                error: null,
            };
        case DELETE_IMAGE_SUCCESS:
            return {
                ...state,
                deleteImageStatus: 'succeeded',
                deletedImageUrl: payload.imageUrl,
                error: null,
            };
        case DELETE_IMAGE_FAIL:
            return {
                ...state,
                deleteImageStatus: 'failed',
                error: payload.error,
            };
        case RESET_DELETE_IMAGE:
            return {
                ...state,
                deleteImageStatus: 'idle',
                deletedImageUrl: null,
                error: null,
            };
        default:
            return state;
    }
};

export default deleteImageReducer;
