import {SIGNUP_FAIL, SIGNUP_PENDING, SIGNUP_SUCCESS, RESET_SIGNUP_STATE} from "../../Actions/Types";
  const initialState = {
    signUp: [],
    UserData: null,
  };
  const signup_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SIGNUP_SUCCESS:
        return {
          ...state,
          signUp: payload.signUp,
          UserData: payload.UserData,
        };
      case SIGNUP_PENDING:
        return {
          ...state,
          signUp: payload.signUp,
          UserData: null,
        };
      case SIGNUP_FAIL:
        return {
          ...state,
          signUp: [],
          UserData: null,

        };
        case RESET_SIGNUP_STATE:
      return {
        ...state,
        signUp: initialState.signUp, 
        UserData: initialState.UserData, 
      };
      default:
        return state;
    }
  };
  export default signup_reducer;