import {FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_PENDING, FORGOT_PASSWORD_SUCCESS, RESET_FORGOT_PASSWORD_STATE, SET_MESSAGE} from "../Types";
import ForgotPasswordService from "../../services/ForgotPasswordService";
import { toast } from "react-toastify";



export const forgotPassword = (email, type) => async (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_PENDING,
      payload: { forgotPassword: "isLoading" },
    });
    return await ForgotPasswordService.forgotPassword(email,type)
      .then(
        (data) => {
          console.log(data);
          if(data.data.status == true)
          {
            dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: { forgotPassword: data.data, UserData : email },
              })
              toast.info("OTP has been sent to you email address", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            else
            { dispatch({
                type: FORGOT_PASSWORD_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: FORGOT_PASSWORD_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  export const resetForgotPasswordState = () => ({
    type: RESET_FORGOT_PASSWORD_STATE,
  });