// imageService.js
import axios from 'axios';
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';

const API_URL = BASE_URL;

class DeleteImageService {
    async deleteImage(userId, imageUrl) {
        interceptor();
        return axios.delete(`${API_URL}Practitioner/DeleteGalleryImage`, {
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json',
            },
            params: { userid: userId ,imageUrl},
           
        });
    }
}

export default new DeleteImageService();
