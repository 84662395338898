import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import { toast } from "react-toastify";

const ConsumerImageChooser = ({ image, onImageChange }) => {
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 1048576) {
            const fileType = file.type.split("/")[1];
            if (["png", "jpg", "jpeg"].includes(fileType)) {
                onImageChange(file);
            } else {
                toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Maximum file size allowed is 1MB.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className="relative w-[240px] h-[240px] rounded-full overflow-hidden border-2 border-gray-300 group">
            <input
                type="file"
                accept="image/*"
                id="fileInput"
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={handleImageChange}
            />
            <img
                src={image
                    ? typeof image === "string"
                        ? image
                        : URL.createObjectURL(image)
                    : "/placeholder_profileImage.png"}
                alt="Preview"
                className="w-full h-full object-cover transition-transform duration-300 group-hover:blur-[2px] group-hover:brightness-40"
            />
            <label
                htmlFor="fileInput"
                className="absolute bottom-[35%] right-[36%] opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
                <IconButton
                    className="p-2 bg-transparent bg-opacity-60 rounded-full shadow-md"
                    component="span"
                >
                    <CameraAlt sx={{ color: 'white', fontSize: '3rem' }} />
                </IconButton>
            </label>
        </div>
    );
};

export default ConsumerImageChooser;