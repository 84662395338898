
import {CANCEL_BOOKING_FAIL, CANCEL_BOOKING_PENDING, CANCEL_BOOKING_SUCCESS, RESET_CANCEL_BOOKING, REJECT_BOOKING_FAIL, REJECT_BOOKING_PENDING, REJECT_BOOKING_SUCCESS, RESET_REJECT_BOOKING, ACCEPT_BOOKING_FAIL, ACCEPT_BOOKING_PENDING, ACCEPT_BOOKING_SUCCESS, RESET_ACCEPT_BOOKING} from "../../Actions/Types"
    const initialState = {
        cancelBooking: "",
        acceptBooking: "",
        rejectBooking: "",
    };
    const cancelBookingReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case CANCEL_BOOKING_SUCCESS:
          return {
            ...state,
            cancelBooking: payload.cancelBooking,
          };
        case CANCEL_BOOKING_PENDING:
          return {
            ...state,
            cancelBooking: payload.cancelBooking,
          };
        case CANCEL_BOOKING_FAIL:
        return {
          ...state,
        };
        case RESET_CANCEL_BOOKING:
        return {
          ...state,
          cancelBooking: "",
        };

        case REJECT_BOOKING_SUCCESS:
          return {
            ...state,
            rejectBooking: payload.rejectBooking,
          };
        case REJECT_BOOKING_PENDING:
          return {
            ...state,
            rejectBooking: payload.rejectBooking,
          };
        case REJECT_BOOKING_FAIL:
        return {
          ...state,
        };
        case RESET_REJECT_BOOKING:
        return {
          ...state,
          rejectBooking: "",
        };


        case ACCEPT_BOOKING_SUCCESS:
          return {
            ...state,
            acceptBooking: payload.acceptBooking,
          };
        case ACCEPT_BOOKING_PENDING:
          return {
            ...state,
            acceptBooking: payload.acceptBooking,
          };
        case ACCEPT_BOOKING_FAIL:
        return {
          ...state,
        };
        case RESET_ACCEPT_BOOKING:
        return {
          ...state,
          acceptBooking: "",
        };


        default:
          return state;
      }
    };
    export default cancelBookingReducer;