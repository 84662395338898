import Header from "../components/Layout/Header";
import Hero from "../components/Layout/Hero";
import SectionWhyChooseUs from "../components/Layout/SectionWhyChooseUs";
import SectionExploreBestPractitio from "../components/Layout/SectionExploreBestPractitio";
import SectionExploreCategories from "../components/Layout/SectionExploreCategories";
import SectionTheHealersApp from "../components/Layout/SectionTheHealersApp";
import Footer from "../components/Layout/Footer";
import React, { useState, useEffect } from "react";

const images = ["/images@2x.png", "/consumer_landing_image02.png", "/consumer_landing_image03.png", "/consumer_landing_image04.png", "/consumer_landing_image05.png"]

const ConsumerLandingPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden mx-auto flex flex-col items-start justify-start max-w-full tracking-[normal] leading-[normal]">
      <section className="self-stretch flex flex-col h-[100vh] items-start justify-start pt-0 px-0 pb-[222px] box-border relative gap-[148px] w-[vw] mq750:gap-[74px] mq1050:pb-36 mq1050:box-border mq450:gap-[37px] mq450:pb-[94px] mq450:box-border">
        <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 overflow-hidden">
          {images.map((image, index) => (
            <img
              key={index}
              className={`absolute h-full w-full object-cover top-0 left-0 transition-opacity duration-1000 ${index === currentImageIndex ? "opacity-100" : "opacity-0"
                }`}
              alt=""
              src={image}
            />
          ))}
          <div className="absolute h-full w-full top-0 right-0 bottom-0 left-0 bg-gray-400 z-1" />
        </div>
        <Header />
        <Hero />
      </section>
      <SectionWhyChooseUs />
      <div className="w-full flex justify-center">
        <SectionExploreBestPractitio />
      </div>
      <div className="w-full flex justify-center">
        <SectionExploreCategories />
      </div>

      <SectionTheHealersApp />
      <Footer />
    </div>
  );
};

export default ConsumerLandingPage;
