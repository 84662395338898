import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PractitionerDashboard from "../pages/PractitionerDashboard";
import ConsumerProfile from "../pages/ConsumerProfile";
const LoginProtected = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const isAuthenticated = !!user; 

  if (!isAuthenticated) {
   
    return <Navigate to="/" />;
  }

  const practitionerRoutes = ["/dashboard", "/services", "/appointments", "/schedule", "/inbox", "/profile"];
  const consumerRoutes = ["/consumer-profile", "/consumer-inbox", "/consumer-schedule"];

  console.log(user.user?.role, "isAuthenticated", consumerRoutes.includes(location.pathname));

  if (user.user?.role === 1 && practitionerRoutes.includes(location.pathname)) {
    return <PractitionerDashboard><Outlet /></PractitionerDashboard>;
  }

  if (user.user?.role === 0 && consumerRoutes.includes(location.pathname)) {
    return <ConsumerProfile><Outlet /></ConsumerProfile>;
  }

  return <Navigate to="/access-denied" />;
};


export default LoginProtected;