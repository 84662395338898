
import {GET_SERVICES_PRACTITIONER_FAIL, GET_SERVICES_PRACTITIONER_PENDING, GET_SERVICES_PRACTITIONER_SUCCESS} from "../../Actions/Types";
    const initialState = {
        getServicesPractitioner: [],
    };
    const get_service_practitioner = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case GET_SERVICES_PRACTITIONER_SUCCESS:
          return {
            ...state,
            getServicesPractitioner: payload.getServicesPractitioner,
          };
        case GET_SERVICES_PRACTITIONER_PENDING:
          return {
            ...state,
            getServicesPractitioner: payload.getServicesPractitioner,
          };
      case GET_SERVICES_PRACTITIONER_FAIL:
        return {
          ...state,
          getServicesPractitioner: [],
        };
        default:
          return state;
      }
    };
    export default get_service_practitioner;