import React from 'react';
import Button from '@mui/material/Button';
import FilterListOffOutlinedIcon from '@mui/icons-material/FilterListOffOutlined';
import IconButton from '@mui/material/IconButton';

const ResetFilterButton = ({ onClick }) => {
    return (
        <IconButton
        onClick={onClick}
            sx={{
                textTransform: 'none',
                padding: '4.25px 12px',
                // gap: '10px',
                borderRadius: '4px',
                border: '1px solid #D9D9D9',
                boxShadow: '0px 2px 0px 0px #00000004',
                background: '#FFFFFF',
                color: "#000000D9",
                '& .MuiButton-startIcon': {
                    color: "#605D64",
                },
            }}
        >
            <FilterListOffOutlinedIcon />
        </IconButton>
    );
};

export default ResetFilterButton;