import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const FilterButton = ({ onClick, open, icon, text }) => {
    return (
        <Button
            id="button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={onClick}
            startIcon={icon}
            sx={{
                textTransform: 'none',
                padding: '4px 12px',
                gap: '10px',
                borderRadius: '4px',
                border: '1px solid #D9D9D9',
                boxShadow: '0px 2px 0px 0px #00000004',
                background: '#FFFFFF',
                color: "#000000D9",
                '& .MuiButton-startIcon': {
                    color: "#605D64",
                },
            }}
        >
            {text}
        </Button>
    );
};

export default FilterButton;