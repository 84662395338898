import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";
import {forgotPassword, resetForgotPasswordState} from "../Actions/Auth/ForgotPasswordAction";
import { useEffect } from 'react';

const ConsumerForgotPassword = () => {
  useEffect(() => {
    dispatch(resetForgotPasswordState());
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPasswordState = useSelector(state => state.forgotPassword);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
    };

    if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid Email Format";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const email = formData.email;
      const type = '0';
      dispatch(forgotPassword(email, type));
    }
  };
  const handleBack = () => {
    navigate("/consumer-sign-in")
  };
  const navigateToNext = () => {
    navigate("/consumer-reset-password");
  };
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-row items-start justify-start py-20 pr-20 pl-[163px] box-border gap-[193px] tracking-[normal] leading-[normal] text-left text-xl text-neutral-10 font-web-primary-h3-primary-dm-sans mq750:gap-[96px] mq750:pl-[81px] mq750:pr-10 mq750:box-border mq450:gap-[48px] mq450:pl-5 mq450:box-border mq1125:flex-wrap">
      <div className="w-[404px] flex flex-col items-start justify-start pt-[275px] px-0 pb-0 box-border min-w-[404px] max-w-full mq750:pt-[179px] mq750:box-border mq750:min-w-full mq1125:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full mq450:gap-[16px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="relative leading-[30px] mq450:text-base mq450:leading-[24px]">
              Forgot Password?
            </b>
            <div className="self-stretch relative text-base leading-[24px]">
              Don’t Worry ! It happens. Please enter your registered email below
              to reset your password.
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full text-xs text-text-secondary font-h5-medium">
          <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Email"
              placeholder="user@email.com"
              required={true}
              fullWidth={true}
              variant="standard"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                Helper text
              </div>
            </div>
          </div>
          <Button
            className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              border: "#f28500 solid 1px",
              borderRadius: "4px",
              "&:hover": { background: "#f28500" },
              height: 60,
            }}
            onClick={handleSubmit}
          >
            {forgotPasswordState.forgotPassword === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
            ) : forgotPasswordState.forgotPassword.status && forgotPasswordState.forgotPassword.status == true ? (
              navigateToNext() 
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            className="w-[152px] h-[42px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
            startIcon={<img width="16px" height="16px" src="/navigate-before.svg" />}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#434343",
              fontSize: "14",
              background: "#fff",
              border: "#d9d9d9 solid 1px",
              borderRadius: "4px",
              "&:hover": { background: "#fff" },
              width: 152,
              height: 42,
            }}
            onClick={handleBack}
          >
            Back to Login
          </Button>
        </div>
      </div>
      <div>
        <img src="/Signup.png" />
      </div>
    </div>
  );
};

export default ConsumerForgotPassword;
