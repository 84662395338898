import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;
class AddSchedule {
    async createPractitionerSchedule(requestData) {
        interceptor(); 
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        return axios.post(API_URL + "PractitionerSchedule/CreateSchedule", requestData, {
            headers: {
                ...authHeader(),
                TimeZoneOffSet: timeZoneOffset,
            },
        });
    }
    async updatePractitionerSchedule(requestData) {
        interceptor(); 
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        return axios.put(API_URL + "PractitionerSchedule/UpdateSchedule", requestData, {
            headers: {
                ...authHeader(),
                TimeZoneOffSet: timeZoneOffset,
            },
        });
    }
}

export default new AddSchedule();