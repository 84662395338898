import { useCallback } from "react";
import React, { useEffect, useState } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
  TextField,
  Icon,
  IconButton,
  Autocomplete,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import { getPopularServices } from "../Actions/Consumer/GetPopularServicesAction"
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../Actions/Catagories/GetCategoriesAction";
import ServiceCard from "../components/SubComponent/ServiceCard";
import Loader from "../components/SubComponent/Loader";
import { Button as BaseButton, buttonClasses } from '@mui/base/Button';
import { getElasticServices, resetElasticServices } from "../Actions/Consumer/ElasticServicesAction";
import { BASE_URL } from "../Constants";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const SearchResultsServices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState(location.state ? location.state.searchText : null);

  const [displayServices, setDisplayServices] = useState([]);

  const filtered = useSelector((state) => state.elasticServices.elasticServices);
  const [selectedCategories, setSelectedCategories] = useState(() => {
    return sessionStorage.getItem('selectedCategories') ? JSON.parse(sessionStorage.getItem('selectedCategories')) : [];
  });
  const allCategories = useSelector((state) => state.getCategories.getAll);
  const allServices = useSelector((state) => state.getServices.getServices);
  const dispatch = useDispatch();
 
  const [selectedFromPrice, setSelectedFromPrice] = useState(() => {
    return sessionStorage.getItem('selectedFromPrice') ? parseInt(sessionStorage.getItem('selectedFromPrice')) : null;
  });
  const [selectedToPrice, setSelectedToPrice] = useState(() => {
    return sessionStorage.getItem('selectedToPrice') ? parseInt(sessionStorage.getItem('selectedToPrice')) : null;
  });
  const [selectedSession, setSelectedSession] = useState(() => {
    return sessionStorage.getItem('selectedSession') || null;
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const pageSize = 1000000000;
  useEffect(() => {
    dispatch(getAll());
    dispatch(getPopularServices(pageNumber, pageSize));
    searchText && searchText.length > 0 && HandleSearch();
  }, []);
  useEffect(() => {
    if (selectedCategories.length === 0 && !selectedFromPrice && !selectedToPrice && !selectedSession && !searchText) {
      dispatch(resetElasticServices());
    }

  }, [selectedCategories, selectedFromPrice, selectedToPrice, selectedSession, searchText]);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const HandleSearch = () => {
    const searchVal = searchText;
    const categoryIds = selectedCategories;
    const sessionType = selectedSession;
    const fromcost = selectedFromPrice;
    const tocost = selectedToPrice;
    if (searchText !== null && searchText.length > 0)
      dispatch(getElasticServices(searchVal, categoryIds, sessionType, fromcost, tocost));
    setCurrentPage(1);
  };
  const HandleSearchApply = () => {
    const searchVal = searchText;
    const categoryIds = selectedCategories;
    const sessionType = selectedSession;
    const fromcost = selectedFromPrice;
    const tocost = selectedToPrice;
    dispatch(getElasticServices(searchVal, categoryIds, sessionType, fromcost, tocost));
    setCurrentPage(1);
  };
  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prevSelectedCategories) => {
      const updatedCategories = prevSelectedCategories.includes(categoryId)
        ? prevSelectedCategories.filter((id) => id !== categoryId)
        : [...prevSelectedCategories, categoryId];

      sessionStorage.setItem('selectedCategories', JSON.stringify(updatedCategories)); // Store in sessionStorage
      return updatedCategories;
    });
  };
  const handleSessionChange = (event) => {
    const sessionValue = event.target.value;
    setSelectedSession(sessionValue);
    sessionStorage.setItem('selectedSession', sessionValue);
  };


 

  const handllePriceChange = (event) => {
    const fromPrice = parseInt(event.target.value);
    setSelectedFromPrice(fromPrice);
    sessionStorage.setItem('selectedFromPrice', fromPrice);

    let toPrice;
    switch (fromPrice) {
      case 0:
        toPrice = 30;
        break;
      case 30:
        toPrice = 60;
        break;
      case 60:
        toPrice = 100;
        break;
      case 100:
        toPrice = 1000;
        break;
      default:
        toPrice = null;
    }

    setSelectedToPrice(toPrice);
    sessionStorage.setItem('selectedToPrice', toPrice);
  };
  const resetToInitialState = () => {
    setSelectedCategories([]);
    sessionStorage.removeItem('selectedCategories');

   
    setSelectedFromPrice(null);
    sessionStorage.removeItem('selectedFromPrice');

    setSelectedToPrice(null);
    sessionStorage.removeItem('selectedToPrice');

    setSelectedSession(null);
    sessionStorage.removeItem('selectedSession');

    setSearchText("");
    dispatch(resetElasticServices());
    setCurrentPage(1);
  };


  useEffect(() => {
    
    const storedFromPrice = sessionStorage.getItem('selectedFromPrice');
    const storedToPrice = sessionStorage.getItem('selectedToPrice');
    const storedCategories = sessionStorage.getItem('selectedCategories');
    const storedSession = sessionStorage.getItem('selectedSession');

    if (storedFromPrice) setSelectedFromPrice(parseInt(storedFromPrice));
    if (storedToPrice) setSelectedToPrice(parseInt(storedToPrice));
    if (storedCategories) setSelectedCategories(JSON.parse(storedCategories));
    if (storedSession) setSelectedSession(storedSession);
  }, []);
  useEffect(() => {
    if (filtered && filtered.results) {
      setDisplayServices(filtered.results);
    }
    else if (filtered && filtered !== "isLoading" && allServices.length > 0) {
      setDisplayServices(allServices);
    }
  }, [allServices, filtered]);
  const handleCardClick = (id) => {
    let service;
    axios.get(BASE_URL + "Consumer/GetServiceDetails", {
      params: {
        serviceId: id
      }
    })
      .then(response => {
        service = response.data.result[0];
        navigate('/service-details', { state: { service } });
      })
      .catch(error => {

        error('Error fetching service details:', error);
      });

  };
  const handlePageChange = (newPage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const totalPages = Math.ceil(displayServices.length / itemsPerPage);
  const currentServices = displayServices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const onServicesTextClick = useCallback(() => {
    navigate("/search-results-services", { state: { searchText } });
  }, [navigate, searchText]);

  const onPractitionersTextClick = useCallback(() => {
    navigate("/search-results-practitioners", { state: { searchText } });
  }, [navigate, searchText]);
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-10 box-border gap-[40px] tracking-[normal] leading-[normal] text-left text-base text-text-primary font-web-primary-h3-primary-dm-sans mq750:gap-[20px]">
      <ConsumerHeader></ConsumerHeader>
      <div className="self-stretch rounded-xl bg-neutral-1 overflow-hidden flex flex-col items-center justify-start py-6 px-5 box-border max-w-full">
        <form className="m-0 w-[1200px] overflow-hidden flex flex-row flex-wrap items-center justify-center gap-[24px] max-w-full"
          onSubmit={(e) => {
            e.preventDefault();
            HandleSearch();
          }}>
          <div className="flex-1 flex flex-row items-center justify-start gap-[16px] min-w-[500px] max-w-[500px] mq750:min-w-full mq1050:flex-wrap">
            <TextField
              className="[border:none] bg-[transparent] h-[50px] w-[642px] font-web-primary-h3-primary-dm-sans text-base text-m3-black max-w-full"
              color="primary"
              size="small"
              placeholder="Search for anything..."
              variant="outlined"
              value={searchText}
              onChange={handleSearchTextChange}
              sx={{
                "& .MuiInputBase-root": { height: "50px" },
                width: "calc(100% - 121px)",
              }}
            />

            {/* <TextField
              color="primary"
              variant="outlined"
              placeholder="Location"
              helperText=""
              sx={{
                "& .MuiInputBase-root": { height: "50px" },
                width: "calc(100% - 121px)",
              }}
            /> */}
          </div>

          <Button
            className="h-[50px] w-[121px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              border: "#f28500 solid 1px",
              borderRadius: "8px",
              "&:hover": { background: "#f28500" },
            }}
            onClick={HandleSearch}
          >
            Search
          </Button>
        </form>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2 box-border max-w-full">
        <div className="flex-1 flex flex-row items-start justify-center py-0 px-5 box-border max-w-full [row-gap:20px] lg:flex-wrap lg:justify-start">
          <div className="h-9 w-4 flex flex-col items-start justify-start pt-7 px-0 pb-0 box-border">
            <div className="w-4 h-2 hidden flex-col items-start justify-start">
              <div className="w-px h-2 relative hidden" />
            </div>
          </div>
          <div className="w-[868px] flex flex-col items-start justify-start pt-2 px-0 pb-0 box-border max-w-full text-13xl">
            <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
              Showing Best Services
            </h1>
          </div>
          <div className="h-9 w-4 flex flex-col items-start justify-start pt-7 px-0 pb-0 box-border">
            <div className="w-4 h-2 hidden flex-col items-start justify-start">
              <div className="w-px h-2 relative hidden" />
            </div>
          </div>
          <div className="w-[857px] hidden flex-row items-start justify-start max-w-full text-text-secondary font-h5-medium">
            <div className="h-7 flex-1 relative tracking-[0.15px] leading-[175%] flex items-center max-w-full">
              Description
            </div>
          </div>
          <div className="w-[300px] flex flex-row items-start justify-between pt-5 pb-[19px] pr-6 pl-[42px] box-border relative gap-[20px] text-center text-neutral-1 mq450:pl-5 mq450:box-border">
            <div className="h-[50px] w-full absolute !m-[0] top-[calc(50%_-_25px)] right-[0px] left-[0px] rounded-13xl bg-neutral-1 box-border border-[1px] border-solid border-whitesmoke-500" />
            <div className="flex flex-row items-start justify-start relative">
              <div className="h-[50px] w-[150px] absolute !m-[0] top-[calc(50%_-_25px)] left-[calc(50%_-_75px)] rounded-tl-13xl rounded-tr-none rounded-br-none rounded-bl-13xl bg-darkorange z-[1]" />
              <div
                className="relative leading-[24px] font-semibold inline-block min-w-[66px] cursor-pointer z-[2]"
                onClick={onServicesTextClick}
              >
                Services
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-px px-0 pb-0 text-neutral-8">
              <div
                className="relative leading-[24px] font-semibold inline-block min-w-[101px] cursor-pointer z-[1]"
                onClick={onPractitionersTextClick}
              >
                Practitioners
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <section className="w-[1400px] pl-10 flex flex-col items-start justify-start gap-[88px] max-w-full text-left text-lg text-neutral-8 font-web-primary-h3-primary-dm-sans lg:gap-[44px] mq750:gap-[22px]">
          <div className="self-stretch h-auto flex flex-row items-start justify-start gap-[40px] max-w-full mq750:gap-[20px]">
            <div className="self stretch h-[700px] w-[280px] shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] bg-neutral-1 shrink-0 flex flex-col items-start justify-start pt-0 px-0 box-border  lg:pb-[570px] lg:box-border mq750:hidden mq750:pb-60 mq750:box-border mq1050:pb-[370px] mq1050:box-border">
              <div className="w-[280px] h-[700px] bg-m3-white overflow-y-auto shrink-0 flex flex-col items-start justify-start p-4 box-border gap-[16px]">
                <div className="self-stretch shadow-[0px_-1px_0px_#f0f0f0_inset] bg-m3-white flex flex-row items-start justify-between pt-0 px-0 pb-2">
                  <b className="relative leading-[28px]">Filters</b>
                  <Button
                    disableElevation={true}
                    color="primary"
                    variant="text"
                    sx={{ borderRadius: "0px 0px 0px 0px", height: 30 }}
                    onClick={resetToInitialState}
                  >
                    Clear All
                  </Button>
                </div>
                <div className="self-stretch bg-m3-white overflow-hidden shrink-0 flex flex-col items-start justify-start text-sm text-m3-black">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <Accordion className="self-stretch">
                      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                        <Typography>Service Category</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {allCategories === "isLoading" ? (
                          <div>Loading...</div>
                        ) : (
                          <div className="self-stretch overflow-hidden flex flex-col items-center justify-start">
                            {allCategories.map((category) => (
                              <FormControlLabel
                                key={category.id}
                                className="self-stretch"
                                label={category.name}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectedCategories.includes(category.id)}
                                    onChange={() =>
                                      handleCategoryChange(category.id)
                                    }
                                  />
                                }
                              />
                            ))}
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  
                    <Accordion className="self-stretch">
                      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                        <Typography>Price</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="self-stretch overflow-hidden flex flex-col items-center justify-start">
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="distance"
                              name="distance"
                              value={selectedFromPrice}
                              onChange={handllePriceChange}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio color="primary" />}
                                label="$0 - $30"
                              />
                              <FormControlLabel
                                value={30}
                                control={<Radio color="primary" />}
                                label="$30 - $60"
                              />
                              <FormControlLabel
                                value={60}
                                control={<Radio color="primary" />}
                                label="$60 - $100"
                              />
                              <FormControlLabel
                                value={100}
                                control={<Radio color="primary" />}
                                label="$100 + "
                              />

                            </RadioGroup>
                          </FormControl>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className="self-stretch">
                      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                        <Typography>Session Type</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="self-stretch overflow-hidden flex flex-col items-center justify-start">
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="distance"
                              name="distance"
                              value={selectedSession || ''}
                              onChange={handleSessionChange}
                            >
                              <FormControlLabel
                                value={2}
                                control={<Radio color="primary" />}
                                label="All"
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio color="primary" />}
                                label="Onsite"
                              />
                              <FormControlLabel
                                value={0}
                                control={<Radio color="primary" />}
                                label="Online"
                              />

                            </RadioGroup>
                          </FormControl>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-center py-0 px-4 gap-[8px]">
                  <Button
                    className="flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
                    color="primary"
                    variant="outlined"
                    sx={{ borderRadius: "0px 0px 0px 0px", height: 32 }}
                    onClick={resetToInitialState}
                  >
                    Reset
                  </Button>
                  <Button
                    className="flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                    color="primary"
                    variant="contained"
                    disabled={!selectedCategories && !selectedFromPrice && !selectedSession}
                    sx={{ borderRadius: "0px 0px 0px 0px", height: 30 }}
                    onClick={HandleSearchApply}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[24px] max-w-[calc(100%_-_256px)] text-5xl text-neutral-11 mq750:max-w-full">
              {displayServices === "isLoading" || filtered === "isLoading" ? (
                <div className="flex items-center justify-center w-full">
                  <Loader />
                </div>
              ) : displayServices.length > 0 ? (
                <div className="flex flex-col items-start justify-start gap-6">
                {currentServices.map((service) => (
                  <div
                    key={service.id}
                    className="cursor-pointer rounded-xl w-full" 
                    onClick={() => handleCardClick(service.id)}
                  >
                    <ServiceCard data={service} />
                  </div>
                ))}
              </div>
              
              ) : (
                <div className="text-sm">No search results found</div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-[20px] w-full">
            <Button className="bg-darkorange"
              variant="contained"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
            <Typography variant="body1">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button className="bg-darkorange"
              variant="contained"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </div>
          <footer className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full text-left text-mid text-gray-200 font-web-primary-h3-primary-dm-sans mq750:gap-[16px]">
            <div className="flex flex-row items-start justify-start gap-[54.9px] max-w-full lg:flex-wrap mq750:gap-[27px]">
              <div className="w-[248px] flex flex-col items-start justify-start gap-[32px] text-mini text-gray-700">
                <div className="w-[236px] flex flex-row items-end justify-start gap-[12.4px]">
                  <img
                    className="relative overflow-hidden"
                    loading="lazy"
                    alt="Logo"
                    src="/Logo.png"
                    style={{ width: '236px', height: '47px' }}
                  />
                </div>
                <div className="self-stretch h-[104px] relative tracking-[-0.1px] leading-[26px] inline-block mix-blend-normal">
                  Your all-in-one platform for effortless wellness, connecting
                  you with curated fitness, wellness services and personalized
                  recommendations.
                </div>
              </div>
              <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
                <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                  Company
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  About us
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Contact us
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Privacy Policy
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Terms and Conditions
                </div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[54.8px] max-w-full mq750:flex-wrap mq750:gap-[27px]">
                <div className="flex flex-col items-start justify-start gap-[16px] min-w-[224px] mq750:flex-1">
                  <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                    Services
                  </div>
                  <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                    Mind
                  </div>
                  <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                    Body
                  </div>
                  <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                    Medicine
                  </div>
                  <div className="relative tracking-[-0.2px] leading-[22px]">
                    Psychologists and Counselors
                  </div>
                </div>
                <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px] min-w-[186.79999999999927px] mq750:flex-1">
                  <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                    Links
                  </div>
                  <div className="self-stretch relative tracking-[-0.2px]">
                    Sitemap
                  </div>
                  <div className="self-stretch relative tracking-[-0.2px]">
                    Security
                  </div>
                  <div className="self-stretch relative tracking-[-0.2px]">
                    Support Center
                  </div>
                  <div className="self-stretch relative tracking-[-0.2px]">
                    Cookie Preferences
                  </div>
                </div>
              </div>
              <div className="w-[135.9px] flex flex-col items-start justify-start gap-[16px] text-4xs-4 text-neutral-1 font-open-sans">
                <img
                  className="self-stretch h-10 relative max-w-full overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/app-store--filled1.svg"
                />
                <div className="self-stretch flex flex-row items-start justify-start">
                  <div className="flex-1 flex flex-row items-end justify-start pt-[4.6px] pb-1.5 pr-[9.2px] pl-2.5 relative gap-[19.8px]">
                    <img
                      className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/vector.svg"
                    />
                    <img
                      className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full z-[1]"
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[3.2px]">
                      <img
                        className="w-[11.1px] h-[21.5px] relative z-[4]"
                        alt=""
                        src="/vector-5.svg"
                      />
                    </div>
                    <img
                      className="h-[13.4px] w-[16.2px] absolute !m-[0] bottom-[7.2px] left-[10.1px] z-[2]"
                      alt=""
                      src="/vector-2.svg"
                    />
                    <img
                      className="h-[11px] w-[12.5px] absolute !m-[0] top-[calc(50%_-_5.5px)] left-[20.5px] z-[3]"
                      alt=""
                      src="/vector-4.svg"
                    />
                    <div className="flex-1 flex flex-col items-start justify-start gap-[1.4px]">
                      <div className="relative inline-block [-webkit-text-stroke:0.2px_#fff] min-w-[40px] z-[2]">
                        GET IT ON
                      </div>
                      <img
                        className="self-stretch h-[17px] relative max-w-full overflow-hidden shrink-0 z-[2]"
                        alt=""
                        src="/vector-3.svg"
                      />
                    </div>
                    <img
                      className="h-[12.9px] w-[16.3px] absolute !m-[0] top-[7.1px] left-[10px] z-[5]"
                      alt=""
                      src="/vector-6.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-[58px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[0.5px] px-0 pb-[55.5px] box-border gap-[29.5px] text-mini mq750:h-auto">
              <img
                className="self-stretch relative max-w-full overflow-hidden max-h-full shrink-0 [debug_commit:1de1738]"
                loading="lazy"
                alt=""
                src="/line-copy.svg"
              />
              <img
                className="w-[15.1px] h-[13px] relative hidden"
                alt=""
                src="/heart2.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px] mq750:flex-wrap">
                <div className="w-[235px] relative tracking-[-0.1px] leading-[36px] inline-block shrink-0 [debug_commit:1de1738]">
                  © 2024 Copyright, All Right Reserved.
                </div>
                <div className="w-48 flex flex-col items-start justify-start pt-[2.1px] px-0 pb-0 box-border">
                  <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px]">
                    <img
                      className="h-6 w-6 relative min-h-[24px]"
                      loading="lazy"
                      alt=""
                      src="/buttontwitter.svg"
                    />
                    <img
                      className="h-6 w-6 relative min-h-[24px]"
                      loading="lazy"
                      alt=""
                      src="/buttonfacebook.svg"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px]"
                      loading="lazy"
                      alt=""
                      src="/logoinstagram@2x.png"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px]"
                      alt=""
                      src="/logolinkedin@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </main>
    </div>
  );
};

export default SearchResultsServices;
