import { useState } from "react";
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Input,
  FormHelperText,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateService, resetUpdateService } from "../../Actions/Practitioner/updateServiceAction";
import { setSelection } from "../../Actions/Practitioner/addServiceAction";
import Loader from "../SubComponent/Loader";
import { useNavigate } from "react-router-dom";

const AddServiceForm = ({ data, categories }) => {
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [online, setOnline] = useState(data.type === 0 || data.type === 2 ? true : false);
  const [offline, setOffline] = useState(data.type === 1 || data.type === 2 ? true : false);
  const [isActive, setIsActive] = useState(data.isActive ? 'active' : 'inactive'); 
  const [cost, setCost] = useState(data.cost);
  const [categoryId, setCategoryId] = useState(data.categoryId);
  const [errors, setErrors] = useState({});
  const UserData = JSON.parse(localStorage.getItem("user"));
  const service = useSelector((state) => state.updateService);
  const navigate = useNavigate();
  const num = 3;

  const validate = () => {
    let tempErrors = {};
    tempErrors.serviceName = serviceName ? "" : "Service Name is required.";
    tempErrors.description = description ? "" : "Description is required.";
    tempErrors.cost = cost ? "" : "Cost is required.";
    tempErrors.categoryId = categoryId ? "" : "Category is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSave = () => {
    if (validate()) {
      const body = {
        name: serviceName,
        description,
        isActive: isActive === 'active', 
        type: online && offline ? 2 : online && !offline ? 0 : !online && offline ? 1 : "",
        cost: parseFloat(cost),
        userId: UserData.user.id,
        categoryId,
        id: data.id,
      };
      dispatch(updateService(body));
    }
  };

  const addSuccess = () => {
    dispatch(resetUpdateService());
    dispatch(setSelection(num));
  };
  const addCancel = () => {
    dispatch(setSelection(num));
  };

  return (
    <div className="h-[600px] self-stretch flex flex-col items-end justify-start gap-[40px] max-w-full min-w-full text-left text-gray-800 font-sans mq750:gap-[20px]">
      <div className="self-stretch flex flex-row items-start justify-start max-w-full text-13xl  text-neutral-10-1000">
        <div className="w-[444px] flex flex-col items-start justify-start gap-[8px] max-w-full">
          <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          Add Service
          </h1>
          
        </div>
      </div>
      <div className="self-stretch h-[47px] flex flex-row items-start justify-between pt-0 px-0 pb-0 box-border max-w-full font-components-helper-text">
        <TextField
          className="[border:none] bg-[transparent] h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-[500px]"
          color="primary"
          label="Service Name"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
          fullWidth={true}
          variant="standard"
          sx={{ "& .MuiInputBase-root": { height: "57px" } }}
          error={Boolean(errors.serviceName)}
          helperText={errors.serviceName}
        />
        <FormControl
          className="h-[53px] flex-1 pt-[10px] font-web-secondary-underline-subtitle2-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-[500px]"
          error={Boolean(errors.categoryId)}
        >
          <InputLabel>Choose Category</InputLabel>
          <Select
            name="category"
            input={<Input label="Choose Category" />}
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  width: 250,
                },
              },
            }}
          >
            {!data ? (
              <MenuItem>loading...</MenuItem>
            ) : (
              categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))
            )}
          </Select>
          {errors.categoryId && <FormHelperText>{errors.categoryId}</FormHelperText>}
        </FormControl>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start max-w-full">
        <TextField
          className="self-stretch font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 max-w-full"
          color="primary"
          rows={5}
          label="Description"
          variant="standard"
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          error={Boolean(errors.description)}
          helperText={errors.description}
        />
      </div>

      <div className="self-stretch h-[47px] flex flex-row items-start justify-between pt-0 px-0 pb-0 box-border max-w-full font-components-helper-text">
      <div className="self-stretch flex flex-col items-start justify-start py-0 pr-[50px] pl-0 gap-[16px] text-sm text-neutral-10-1000 mq1050:pr-[373px] mq1050:box-border mq750:pr-[186px] mq750:box-border">
        <div className="relative leading-[22px] inline-block min-w-[91px]">
          Service Type
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] text-base mq450:flex-wrap">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <input
              className="accent-highlight-darkest m-0 h-6 w-6 relative rounded-md box-border min-h-[24px] border-[1.5px] border-solid border-highlight-darkest"
              checked={online === true}
              type="checkbox"
              onClick={() => setOnline(!online)}
            />
            <div className="relative leading-[24px] inline-block min-w-[48px]">
              Online
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[8px]">
            <input
              className="accent-highlight-darkest m-0 h-6 w-6 relative rounded-md box-border min-h-[24px] border-[1.5px] border-solid border-highlight-darkest"
              checked={offline === true}
              type="checkbox"
              onClick={() => setOffline(!offline)}
            />
            <div className="relative leading-[24px] inline-block min-w-[58px]">
              On-site
            </div>
          </div>
          
        </div>
       

      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-0 pr-[50px] pl-0 gap-[6px] text-sm text-neutral-10-1000 mq1050:pr-[373px] mq1050:box-border mq750:pr-[186px] mq750:box-border">
        <div className="relative leading-[22px] inline-block min-w-[91px]">Status</div>
        <RadioGroup
          className="self-stretch flex flex-row items-start justify-between gap-[10px] text-base mq450:flex-wrap"
          value={isActive}
          onChange={(e) => setIsActive(e.target.value)}
        >
          <FormControlLabel value="active" control={<Radio />} label="Active" />
          <FormControlLabel value="inactive" control={<Radio />} label="In Active" />
        </RadioGroup>
       
      </div>
      <TextField
          className="[border:none] bg-[transparent] pt-4 h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[200px] max-w-[200px]"
          color="primary"
          label="Service Cost"
          type="number"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          fullWidth={true}
          variant="standard"
          sx={{ "& .MuiInputBase-root": { height: "57px" } }}
          error={Boolean(errors.cost)}
          helperText={errors.cost}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        />
        </div>
        <div className="w-[354px] h-[100px] flex flex-row items-start justify-start py-0 pr-0 pt-12 pl-5 box-border gap-[14px] max-w-full">
        <Button
          className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "18",
            background: "#fff",
            border: "#d9d9d9 solid 1px",
            borderRadius: "4px",
            "&:hover": { background: "#fff" },
          }}
          onClick={addCancel}
        >
          Cancel
        </Button>
        <Button
          className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "18",
            background: "#006a6a",
            border: "#006a6a solid 1px",
            borderRadius: "4px",
            "&:hover": { background: "#006a6a" },
          }}
          onClick={handleSave}
        >
          {service.updateService === 'isLoading' ? (
            <div>
              <Loader />
            </div>
          ) : service.updateService.status === true ? (
            addSuccess()
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddServiceForm;