import axios from "axios";
import { BASE_URL } from "../Constants";
const API_URL = BASE_URL;
class ForgotPasswordService
{
    async forgotPassword(email, type)
    {
        return axios.post(API_URL + "Auth/ForgotPassword?email=" + email + "&userType=" + type);
    }

    async confirmForgotPassword(body)
    {
        return axios.post(API_URL + "Auth/ConfirmForgotPassword", body);
    }

}
export default new ForgotPasswordService