import {
    TextField,
    InputAdornment,
    Icon,
    IconButton,
    Button,
  } from "@mui/material";
  import { useState } from "react";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import { useDispatch, useSelector } from "react-redux";
  import Loader from "../components/SubComponent/Loader";
  import {forgotPassword, resetForgotPasswordState} from "../Actions/Auth/ForgotPasswordAction";
  import { useEffect } from 'react';

const PractitionerForgotPassword = () => {
    useEffect(() => {
        dispatch(resetForgotPasswordState());
      }, []);
      const navigate = useNavigate();
      const dispatch = useDispatch();
      const forgotPasswordState = useSelector(state => state.forgotPassword);
      const [formData, setFormData] = useState({
        email: "",
      });
      const [errors, setErrors] = useState({
        email: "",
      });
      const validateForm = () => {
        let valid = true;
        const newErrors = {
          email: "",
        };
    
        if (!isValidEmail(formData.email)) {
          newErrors.email = "Invalid Email Format";
          valid = false;
        }
    
        setErrors(newErrors);
        return valid;
      };
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
        setErrors({
          ...errors,
          [name]: "",
        });
      };
      const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
          const email = formData.email;
          const type = '1';
          dispatch(forgotPassword(email, type));
        }
      };
      const handleBack = () => {
        navigate("/practitioner-sign-in")
      };
      const navigateToNext = () => {
        navigate("/practitioner-reset-password");
      };
  return (
    <div className="w-full relative bg-m3-sys-light-on-primary overflow-hidden flex flex-row items-start justify-start py-10 pr-10 pl-[140px] box-border gap-[47px] tracking-[normal] leading-[normal] mq750:gap-[23px] mq750:pl-[70px] mq750:box-border mq450:pl-5 mq450:box-border mq1125:flex-wrap">
      <div className="w-[480px] flex flex-col items-start justify-start pt-8 px-0 pb-0 box-border min-w-[480px] max-w-full mq750:min-w-full mq1125:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[257px] max-w-full mq750:gap-[128px]">
          <div className="w-[302px] flex flex-row items-end justify-start gap-[15.9px]">
          <img
    className="relative overflow-hidden"
    loading="lazy"
    alt="Logo"
    src="/PractLogo.png" 
    style={{ width: '236px', height: '47px' }} 
  />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full mq450:gap-[16px] mt-40">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="relative leading-[30px] mq450:text-base mq450:leading-[24px]">
              Forgot Password?
            </b>
            <div className="self-stretch relative text-base leading-[24px]">
              Don’t Worry ! It happens. Please enter your registered email below
              to reset your password.
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full text-xs text-text-secondary font-h5-medium">
          <TextField
              className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Email"
              placeholder="user@email.com"
              required={true}
              fullWidth={true}
              variant="standard"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ "& .MuiInputBase-root": { height: "53px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                Helper text
              </div>
            </div>
          </div>
          <Button
            className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
            variant="contained"
            sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                background: "#006a6a",
                border: "#006a6a solid 1px",
                borderRadius: "4px",
                "&:hover": { background: "#006a6a" },
              }}
            onClick={handleSubmit}
          >
            {forgotPasswordState.forgotPassword === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
            ) : forgotPasswordState.forgotPassword.status && forgotPasswordState.forgotPassword.status == true ? (
              navigateToNext() 
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            className="w-[152px] h-[42px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
            startIcon={<img width="16px" height="16px" src="/navigate-before.svg" />}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#434343",
              fontSize: "14",
              background: "#fff",
              border: "#d9d9d9 solid 1px",
              borderRadius: "4px",
              "&:hover": { background: "#fff" },
              width: 152,
              height: 42,
            }}
            onClick={handleBack}
          >
            Back to Login
          </Button>
        </div>
      </div>
      <div className="flex-1 flex flex-row items-start justify-start min-w-[476px] max-w-full mq750:min-w-full">
        <div className="h-[944px] flex-1 relative max-w-full">
          <img
            className="absolute h-full top-[0px] bottom-[0px] left-[93px] rounded-3xl max-h-full w-[640px] object-cover"
            loading="lazy"
            alt=""
            src="/chrislee70l1tdai6rmunsplash-2@2x.png"
          />
          <div className="absolute top-[634px] left-[0px] [backdrop-filter:blur(22.2px)] bg-opacity-35 rounded-[50%] bg-teal-300 box-border w-[187px] h-[187px] z-[1] border-[0px] border-solid border-teal-200" />
        </div>
      </div>
    </div>
  );
};

export default PractitionerForgotPassword;