import {ADD_CATEGORY_FAIL, ADD_CATEGORY_PENDING, ADD_CATEGORY_SUCCESS, RESET_ADD_CATEGORY} from "../../Actions/Types";

const initialState = {
    addCategory: []
}

const categories_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case ADD_CATEGORY_SUCCESS:
        return {
          ...state,
          addCategory: payload.addCategory,
        };
      case ADD_CATEGORY_PENDING:
        return {
          ...state,
          addCategory: payload.addCategory,
        };
      case ADD_CATEGORY_FAIL:
        return {
          ...state,
          addCategory: [],

        };
        case RESET_ADD_CATEGORY:
          return {
            ...state,
            addCategory: [],
  
          };
      default:
        return state;
    }
  };
  export default categories_reducer ;